@import './init';

// global card styling
.card {
  box-shadow: none;
  border-radius: 0;
  border: none;
  // background-color: $danger-color;
  &-title {
    @include title-heavy-h3;
    color: black !important;
    margin-bottom: 1em;
  }
  &-subtitle {
    @include title-light-h3;
    color: black;
    margin-bottom: 1em;
  }
  &-subheader {
    @include subheader-light-h4;
  }
  &-paragraph {
    @include body-light;
    color: $giftano-gray-200;
    &-action {
      margin-top: 0.5em;
    }
  }
  &-inner {
    border: 1px solid $giftano-semiwhite;
    border-radius: $giftano-radius;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    // box-shadow: $giftano-dropshadow;
  }
  &-body {
    margin-bottom: 15px;
  }
}

.widget-card {
  margin-top: 20px;
  margin-bottom: 35px;
  padding: 10px 15px;
}

@media only screen and (max-width: 767px) {
  .card {
    @include mobile-body-heavy;
    height: 100%;
    max-width: 100% !important;
    position: relative !important;
    margin-top: 1em !important;
    &-body {
      // padding-left: 0.5em;
      // padding-right: 0.5em;
    }
    &-title {
      @include mobile-title-heavy-h3;
    }
    &-subtitle {
      @include mobile-title-light-h3;
    }
    &-subheader {
      @include mobile-subheader-light-h4;
    }
    &-paragraph {
      @include mobile-body-light;
    }
  }
  .widget-card {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .portal-page {
    .card {
      position: absolute !important;
      max-height: 75% !important;
      max-width: 700px !important;
      margin-top: auto !important;
      left: 15px !important;
      right: 15px !important;
    }
  }
}

@media (max-width: 623px) {
  .portal-page {
    .card {
      position: absolute !important;
      max-height: max-content !important;
      height: max-content;
    }
  }
}
