.font-weight {
  &-bold {
    font-weight: bold;
  }
}

.error {
  &-404 {
    font-weight: bold;
    font-size: 120px;
  }
  &-message{
    font-weight: normal;
    font-size: 24px;
  }
}
