@import './init';

.account-page {
  .member {
    &-image {
      text-align: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100%;
      margin-bottom: 1em;
      height: 10em;
      width: 10em;
      object-fit: cover;
    }
    &-name {
      @include subheader-heavy-h4;
    }
    &-balance {
      @include subheader-heavy-h4;
      span {
        @include subheader-heavy-h4;
        color: $giftano-primary !important;
      }
    }
    &-gifts {
      @include subheader-heavy-h4;
      color: $giftano-secondary;
    }
  }
}

@media only screen and (max-width: 767px) {
  .account-page {
    .member {
      &-image {
        margin: 1.5em auto;
        display: block;
      }
    }
  }
}
