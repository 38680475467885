@import './init';

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
  background: transparent;
}
.btn {
  transition: $giftano-transition;
  &-transparent {
    background-color: transparent;
    border: none;
    &:hover {
      background-color: transparent;
      border: none;
    }
  }
  &:focus {
    box-shadow: none !important;
  }
  &-back {
    letter-spacing: 1px;
    color: #000 !important;
    cursor: pointer;
    font-weight: 400;
  }
  &-facebook {
    display: block;
    border: 1px solid $giftano-gray-100 !important;
    width: 100%;
    background-color: #1877f2;
    color: #fff !important;
    font-weight: bold;
    border-radius: 2em;
    transition: 0.3s ease-in background-color;
    &:hover {
      background-color: #303f9f !important;
    }

    &:disabled {
      display: block;
      border: 1px solid $giftano-gray-100 !important;
      width: 100%;
      background-color: #1877f2;
      color: #fff !important;
      font-weight: bold;
      border-radius: 2em;
      transition: 0.3s ease-in background-color;
    }
  }
  &-apple {
    display: block;
    border: 1px solid $giftano-gray-100 !important;
    width: 100%;
    background-color: var(--gf-neutral-500);
    color: #fff !important;
    font-weight: bold;
    border-radius: 2em;
    transition: 0.3s ease-in background-color;
  }

  &-cta-guest {
    text-decoration: underline;
    cursor: pointer;
    text-underline-offset: 6px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    &:hover {
      text-decoration: underline;
      text-underline-offset: 6px;
    }
  }

  &-fix-width {
    width: 15em;
    margin: 0 auto;
    text-decoration: none;
  }
  &-fix-width-sm {
    min-width: 12em;
  }
  &-pop {
    background-color: transparent;
    border: none;
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  }
  &-close:focus {
    outline: 0;
    box-shadow: none;
    opacity: 1;
  }
  &-stretched {
    width: 96%;
    height: initial;
    text-decoration: none;
  }
  &-image {
    width: 100%;
    display: flex;
    min-height: 100px;
    height: auto;

    background-color: white;
    filter: drop-shadow(
      0px 2px 4px rgba(0, 0, 0, 0.16)
    ); // justify-content: center;
    align-content: center;
    align-items: center;
    transition: all 0.3s ease-in;
    flex-direction: column;
    &:hover {
      background-color: var(--gf-light-gray-100);
      box-shadow: var(--gf-shadow-normal);
      svg path {
        fill: white;
      }
    }
    &:focus {
      background-color: $giftano-secondary-hover;
    }
    &--home {
      background-color: $giftano-primary;
    }
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }

  &-forgot-password {
    margin-top: -1.5em;
  }

  svg {
    vertical-align: middle;
  }
  height: 45px;
}

.btn-primary {
  @include button-color($primary-color);
  @include generalFont($white-color);
  font-weight: 600;
  border: none;
  border-radius: 2em;
  &:hover {
    background-color: $giftano-primary-hover !important;
    text-decoration: none;
  }
  &:focus,
  &:active {
    background-color: $giftano-primary-focus;
  }
  &:disabled {
    background-color: #028c80;
  }
}

// .btn.disabled,
// .btn:disabled,
// fieldset:disabled .btn {
//   background-color: transparent;
// }

.btn-warning {
  @include button-color($giftano-secondary);
  @include generalFont($white-color);
  border: none;
  border-radius: 2em;
  &:hover {
    background-color: $giftano-secondary-hover;
    text-decoration: none;
  }
  &:focus,
  &:active {
    background-color: $giftano-secondary-focus;
  }
}

.btn-danger {
  @include button-color($danger-color);
  @include generalFont($white-color);
  border: none;
  border-radius: 2em;
  &:hover {
    background-color: $giftano-danger-hover;
    text-decoration: none;
  }
  &:focus,
  &:active {
    background-color: $giftano-danger-active;
  }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  @include button-color($primary-color);
  @include generalFont($white-color);
  border: none;
  &:hover {
    background-color: $giftano-primary-focus;
  }
}

.btn-info {
  @include button-color($giftano-info);
  @include generalFont($giftano-lightblue);
  border: none;
  border-radius: 2em;
  &:hover {
    background-color: $giftano-info-hover;
  }
}

.btn-outline {
  &-primary {
    background-color: var(--gf-light-gray-100);
    color: $giftano-primary !important;
    border: 1px solid $giftano-primary;
    border-radius: 2em;
    padding: 0.375rem 0.75rem;
    // line-height: 1.6;
    svg {
      width: 0.6em;
      height: 0.6em;
      margin-bottom: 1px;
    }
    &:hover {
      border: 1px solid $giftano-primary-hover !important;
      color: $giftano-primary-hover !important;
      background-color: $giftano-gray-50 !important;
      // box-shadow: $giftano-dropshadow;
    }
    img {
      color: $giftano-primary !important;
      margin-right: 8px;
      font-size: 20px;
      padding: 0;
    }
  }
  &-dark {
    background-color: #fff;
    color: #5c5c5c !important;
    border: 1px solid #5c5c5c;
    border-radius: 2em;
    padding: 0.375rem 0.75rem;
    // line-height: 1.6;
    svg {
      width: 0.6em;
      height: 0.6em;
      margin-bottom: 1px;
    }
    &:hover {
      border: 1px solid $giftano-primary-hover;
      color: $giftano-primary-hover !important;
      background-color: $giftano-gray-50;
      // box-shadow: $giftano-dropshadow;
    }
    img {
      color: #5c5c5c !important;
      margin-right: 8px;
      font-size: 20px;
      padding: 0;
    }
  }

  &-danger {
    background-color: #fff;
    color: $danger-color !important;
    border: 1px solid $danger-color;
    border-radius: 2em;
    padding: 0.375rem 0.75rem;
    line-height: 1.6;
    svg {
      width: 0.6em;
      height: 0.6em;
      margin-bottom: 1px;
    }
    &:hover {
      border: 1px solid $giftano-danger-hover;
      color: $giftano-danger-hover !important;
      background-color: $giftano-gray-50;
      box-shadow: $giftano-dropshadow;
    }
  }
  &-info {
    background-color: #fff;
    color: $giftano-info !important;
    border: 1px solid $giftano-info;
    border-radius: 2em;
    padding: 0.375rem 0.75rem;
    line-height: 1.6;
    svg {
      width: 0.6em;
      height: 0.6em;
      margin-bottom: 1px;
    }
    &:hover {
      border: 1px solid $giftano-info-hover;
      color: $giftano-info-hover;
      background-color: $giftano-gray-50;
      box-shadow: $giftano-dropshadow;
    }
  }
}

.btn-login {
  width: $btn-login-width;
  // border-radius: $giftano-radius;
}

.btn.sm-rounded {
  border-radius: $sm-rounded;
}
.btn.md-rounded {
  border-radius: $md-rounded;
}

.btn.md-rounded {
  border-radius: $lg-rounded;
}

.dropdown-menu {
  left: auto;
  right: 0;
  // background-color: $primary-color;

  top: calc(100% + 10px);
}

.btn-google {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: 600;
  &:hover {
    background-color: #fff !important;
    color: #000;
  }
}

.card-dropdown .dropdown-menu.show {
  left: auto !important;
  right: 0;
  background-color: $primary-color;
  button,
  a {
    color: #fff !important;
    transition: 0.3s ease-in background-color;
    &:hover {
      color: $primary-color !important;
    }
  }
  &:before {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(0, 0, 0, 0.15);
    content: '';
    position: absolute;
    right: 8px;
    bottom: 100%;
    display: block;
  }
  :after {
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid $primary-color;
    content: '';
    position: absolute;
    right: 9px;
    bottom: 100%;
    display: block;
  }
}

.login--tab {
  border-bottom: 1px solid $giftano-primary;
}

.nav-button {
  width: 100%;
  display: block;
  text-align: center;
  cursor: pointer;
  // border-radius: 2em;
  border: 1px solid transparent;
  @include subheader-heavy-h4;
  background-color: transparent;
  color: black !important;

  &.active {
    // border: 1px solid $giftano-primary-focus;
    background-color: white;
    color: $giftano-primary !important;
    // box-shadow: $giftano-dropshadow;
    border-bottom: 1px solid $giftano-primary;
  }
  &:hover {
    border-bottom: 1px solid $giftano-primary-hover;
    background-color: white;
    color: $giftano-primary-hover !important;
    text-decoration: none;
    // box-shadow: $giftano-dropshadow;
  }
}

@media only screen and (max-width: 767px) {
  .card-dropdown .dropdown-menu.show {
    transform: none !important;
    top: 3em !important;
  }
}
