@include responsive($breakpoint-phone) {
  .portal-email-and-fullname {
    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      height: 100%;
      .profile-email {
        text-align: left !important;
      }
      .profile-name {
        margin-bottom: 2px;
        margin-top: 0px;
      }
    }
  }

  .wrapper-apps {
    padding: 10px;
  }
}
