@import './init';
// @import "../fonts/icons/style.css";
.field-error {
  // background-color: #5c5c5c;
  border: 2px solid red !important;
}

input.text-capitalize {
  text-transform: capitalize;
}
.wrapper-login {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  height: 100%;
}
.layoutWrapper {
  // border: 2px solid red;
  height: 100%;
}
.forgot-label {
  transform: translateY(-60px);
}

.justify-content-evenly {
  min-height: 450px;
  align-items: center;
}

.portal-page {
  .login-container {
    .card {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      // height: 60%;
      // max-height: 84%;
      max-height: max-content;

      min-height: 450px;
      max-width: 900px;
      border-radius: var(--gf-border-radius-m);
    }
  }
}

.otp-container {
  max-width: 75%;
}

.login-container {
  width: 100%;
  margin: auto;
  .card {
    min-height: 50vh;
    border-radius: 22px;
  }
  .profile-name {
    color: white !important;
    font-weight: 400;
    font-size: 24px;
    // color: black;
    // margin-left: 0.6em;
    margin-bottom: 0.6em;
    text-align: center;
    line-height: 1.2;
    margin-top: 20px;
  }
  .profile-email {
    color: white !important;
    font-weight: 400;
    // color: black;
    /* margin-left: 1em; */
    // margin-bottom: 0.6em;
    text-align: center;
    line-height: 1.2;
  }
}

.form-login {
  .form-group {
    label {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .forgot-link {
    margin-top: -1rem;
  }
}

.fs-14 {
  font-size: 14px;
}
.form-group.new-account {
  margin: 0;
  p {
    font-size: 16px;
    padding: 0;
    margin: 0;
  }
}

.MuiAutocomplete-inputRoot {
  margin: 0 !important;
}

.MuiAutocomplete-root,
.scope--721888386 .MuiInput-root {
  margin: 0 !important;
}

a.login-forgot {
  font-size: 14px !important;
}

.login-divider {
  position: relative;
  color: #5c5c5c;
  &:before {
    content: '';
    position: absolute;
    height: 1px;
    width: 54px;
    background-color: #5c5c5c;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    left: -6em;
  }
  &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 54px;
    background-color: #5c5c5c;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: -6em;
  }
}

input.login-input {
  @include border-rad(0);
  @include bordered(0, 1px, 0, 0);

  &:focus {
    @include bordered(0, 1px, 0, 0);
    border-color: $primary-color;
    outline: none !important;
    @include box-shadow(0px, 0px, 0px, $shadow-color, inherit);
  }
}

.login {
  &-forgot {
    position: absolute;
    right: 0;
    top: -7px;
  }
}

.scope-1140987041 .MuiAutocomplete-root,
.scope-1140987041 .MuiInput-root {
  margin-bottom: 0 !important;
}

@media (max-width: 1023px) {
  .login-container {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .login-container {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .auth-page .login-block {
    position: relative;
  }
}
@media (max-width: 991px) {
  .auth-page .login-block {
    position: absolute;
    margin-top: auto !important;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .otp-container {
    max-width: 400px;
  }
}
