$giftano-primary: var(--gf-primary-300);
$giftano-secondary: var(--gf-warning-300);
$giftano-darkpurple: var(--gf-dark-violet-300);
$giftano-lightblue: var(--gf-light-gray-300);
$giftano-info: var(--gf-violet-300);
$giftano-semiwhite: #e5eaee;
$giftano-darkgray: #333333;
$giftano-gray-200: #4f4f4f;
$giftano-gray-250: #464e5f;
$giftano-gray-300: #828282;
$giftano-gray-350: #b5b5c3;
$giftano-gray-400: #bdbdbd;
$giftano-gray-100: #fafafa;
$giftano-gray-50: #f3f6f9;
$giftano-radius: 6px;
$giftano-dropshadow: 0px 0px 35px 0px rgba(181, 181, 195, 0.15);

// color state
$giftano-primary-hover: var(--gf-primary-400);
$giftano-primary-focus: var(--gf-primary-400);
$giftano-secondary-hover: var(--gf-warning-400);
$giftano-secondary-active: var(--gf-warning-400);
$giftano-secondary-focus: var(--gf-warning-300);
$giftano-danger-active: var(--gf-error-300);
$giftano-danger-hover: var(--gf-error-400);
$giftano-info-hover: var(--gf-violet-400);

// coloring
$primary-color: $giftano-primary;
$secondary-color: $giftano-gray-350;
$warning-color: $giftano-secondary;
$dark-color: #4f4f4f;
$danger-color: var(--gf-error-300);

// font style
$font-family: 'Lato', sans-serif;

// btn widthing
$btn-login-width: 15%;

// rounded
$sm-rounded: 10px;
$md-rounded: 15px;
$lg-rounded: 20px;
$white-color: #fff;
$shadow-color: #d8d8d8;

// animation
$giftano-transition: 0.3s ease-in;

// responsive
$breakpoint-tablet: 768px;
$breakpoint-phone: 480px;
$breakpoint-destop: 1024px;
$breakpoint-desktop-high: 1200px;

$retina: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';

:root {
  --toastify-icon-color-error: #fff;
  --toastify-icon-color-success: #fff;
  --toastify-icon-color-error: #fff;
}
