.nav {
  &-tabs {
    justify-content: space-around;
    border-bottom: none;
    .nav-link {
      width: 100%;
      text-align: center;
      cursor: pointer;
      border-radius: 2em;
      border: 1px solid $giftano-semiwhite;
      @include subheader-heavy-h4;
      background-color: $giftano-semiwhite;
      color: $giftano-darkpurple !important;
      &.active {
        border: 1px solid var(--gf-primary-400);
        background-color: white;
        color: var(--gf-primary-400) !important;
        box-shadow: $giftano-dropshadow;
      }
      &:hover {
        border: 1px solid var(--gf-primary-400);
        background-color: white;
        color: var(--gf-primary-400) !important;
        box-shadow: $giftano-dropshadow;
      }
    }
  }
  &-item {
    width: 45%;
  }
}

@media only screen and (max-width: 767px) {
  .nav {
    &-item {
      width: 90%;
      margin-bottom: 1em;
    }
  }
}
