.vertical-space {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text-titile-app {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.4em;
  min-height: 3em;
}

.text-black {
  color: #000 !important;
  font-weight: 600;
}
.text-orange {
  color: $giftano-secondary !important;
}

.text-gf-primary {
  color: var(--gf-primary-300);
  &:hover {
    color: var(--gf-primary-400);
  }
}
.text-gf-neutral {
  color: var(--gf-neutral-400);
  &:hover {
    color: var(--gf-neutral-500) !important;
  }
}

.Toastify__toast-icon {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 10px;
}

.Toastify__close-button {
  color: #fff !important;
}
.Toastify__toast-body {
  width: 100%;
}

@media (max-width: 767px) {
  .text-titile-app {
    font-size: 12px;
    font-weight: 400;
  }
}
