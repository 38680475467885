.react-bootstrap-table-pagination {
  flex-direction: row-reverse;
  & > div:first-child {
    width: 50%;
    text-align: end;
  }
  & > div:last-child {
    width: 50%;
    display: flex;
  }
  //   flex-direction: row-reverse;
  #pageDropDown {
    background-color: white;
    border-color: $giftano-semiwhite;
    color: $giftano-gray-200 !important;
  }
  .active {
    &.page-item {
      .page-link {
        background-color: $giftano-primary;
        border-color: $giftano-primary;
        color: white;
        border-radius: 100%;
      }
    }
  }

  .page-item {
    .page-link {
      line-height: 1;
    }
  }
  &-list {
    // flex-direction: row-reverse;
  }
}
