@import './mixins';
@import './variables';

.loading-spinner {
  margin: 1.5em 0;
}

.modal__no-header {
  /* padding-top: 0; */
  border-bottom: 0;
  margin-bottom: -30px;
  z-index: 20;
}

.modal__no-header .btn-close {
  color: var(--gf-primary-300) !important;
  margin-right: 5px;
  margin-top: 5px;
  background: url(https://giftano.com/wimg/asset/times-circle-hv.png);
  width: 24px;
  height: 24px;
  background-size: cover;
}

@include responsive($breakpoint-destop) {
  .h-inherit {
    min-height: inherit;
  }
}

@include responsive($breakpoint-tablet) {
  .account-greet {
    display: none;
  }
  .h-inherit {
    min-height: auto;
  }
}

@include responsive($breakpoint-phone) {
  .account-greet {
    display: none;
  }
  .h-inherit {
    min-height: auto;
  }
}

.fs-20 {
  font-size: 20px;
}

// @media only screen and (max-width: 767px) {
//   .bg-account {
//     display: none;
//   }
// }

@media only screen and (max-width: 450px) {
  giftano-auth {
    width: 100%;
  }
  .giftano-component {
    padding: 0 !important;
    min-height: 0;
  }
  .grecaptcha-badge {
    display: none !important;
  }
}

@include screenresponsiveMax($breakpoint-phone) {
  @include background-gradient();
}

@include screenresponsiveMax($breakpoint-tablet) {
  @include background-gradient();
}

.auth-page .bg-account {
  &::before {
    filter: none;
  }
}
.bg-account {
  background-size: cover;
  position: relative;
  &::before {
    background-image: url('https://giftano.imgix.net/assets/giftano-account.90d01e95e357473f85ab.png?auto=format');
    content: '';
    filter: blur(6px);
    height: 100%;
    width: 100%;
    position: absolute;
    background-size: cover;
    background-position: center;
    left: 0;
    top: 0;
  }
}
.bg-default {
  background-size: cover;

  // background-image: url("https://giftano.imgix.net/assets/giftano-account.90d01e95e357473f85ab.png?auto=format");
}

// .bg-corporate {
// background-image: url("https://images.unsplash.com/photo-1574725097509-fb5d23a9d86d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80");
// }

.bg-team {
  background-image: url('https://thegreatroom.co/wp-content/uploads/2021/05/image-1.jpg');
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .login-block {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    // margin-top: 10vh;
    /* border-radius: 26px; */
  }

  // .bg-account {
  //   // background-repeat-y: repeat;
  //   background-size: cover;
  //   height: 150vh;
  //   filter: blur(2.1px) brightness(0.9);
  // }

  .login-block .card {
    border-radius: 4px;
  }
}
