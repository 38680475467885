* {
  font-family: 'Lato';
}

html {
  min-width: 100%;
  height: 100%;
}

body {
  background: #ffff !important;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  position: relative;
  // border: 2px solid red;
}

#root {
  height: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import './init';

// modular component
@import './card';
@import './auth';
@import './form';
@import './button';
@import './table';
@import './nav';
@import './pagination';

// page
@import './login';
@import './layout';

@import './account';
@import './order';
@import '../fonts/icons/style.css';
@import './text';
@import './portal';
a {
  transition: $giftano-transition;
}

h1,
h2,
h3,
h5,
h6,
span,
label,
button,
input,
small {
  @include generalFont($giftano-gray-200);
}

h4 {
  @include subheader-heavy-h4;
}

p {
  @include body-light;
  color: $giftano-gray-200;
}

a.nav-link {
  // color: $giftano-primary !important;
  display: inline-block;
  padding-left: 0.5rem !important;

  &:hover {
    text-decoration: none;
    color: $giftano-primary-hover !important;
  }
}

svg {
  margin-bottom: 1px;
}

@for $i from 1 to 20 {
  .w-#{($i * 5)} {
    @include min-widthing($i * 5);
  }
}

@for $i from 1 to 9 {
  .font-w-#{($i * 100)} {
    font-weight: $i * 100 !important;
  }
}

@for $i from 1 to 50 {
  .fs-#{($i)} {
    font-size: $i * 1px;
  }
}

.text-primary {
  @include generalFont($primary-color);
}

.text-dark {
  @include generalFont($dark-color);
}

.radius-none {
  @include border-rad(0);
}

.sm-radius {
  @include border-rad($sm-rounded);
}

.lg-radius {
  @include border-rad($lg-rounded);
}

.md-radius {
  @include border-rad($md-rounded);
}

.sm-shadow {
  -webkit-box-shadow: 0px 0px 39px -12px $shadow-color;
  -moz-box-shadow: 0px 0px 39px -12px $shadow-color;
  box-shadow: 0px 0px 39px -12px $shadow-color;
}

.md-shadow {
  @include box-shadow(0px, 0px, 10px, $shadow-color, inherit);
}

.lg-shadow {
  @include box-shadow(0px, 0px, 15px, $shadow-color, inherit);
}

.text-danger {
  @include fontColor($danger-color);
}

.text-warning {
  @include fontColor($warning-color);
}

.text-info-giftano {
  color: $giftano-info;
  transition: 0.3s ease-in color;
  &:hover,
  &:focus,
  &:active {
    color: $giftano-info-hover;
    text-decoration: none;
  }
}

.bg-white {
  background-color: $white-color;
}

@for $i from 1 to 9 {
  .font-weight-#{($i * 100)} {
    @include font-weight($i * 100);
  }
}

@media (min-width: 767px) {
  .c.portal-container {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .profile-scaffold,
  .menu-grid {
    width: 100% !important;
  }
  .card-portal {
    padding: 0px !important;
  }
  .profile-scaffold {
    border-radius: 0px !important;
  }
}
@media (min-width: 1024px) {
  .portal-page .login-container .card {
    max-height: 85% !important;
  }
}
