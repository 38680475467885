@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ggb123');
  src:  url('fonts/icomoon.eot?ggb123#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ggb123') format('truetype'),
    url('fonts/icomoon.woff?ggb123') format('woff'),
    url('fonts/icomoon.svg?ggb123#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-google-plus-circle:before {
  content: "\e90b";
}
.icon-google-wallet:before {
  content: "\e90c";
}
.icon-google-plus-square:before {
  content: "\e90d";
}
.icon-google-plus1:before {
  content: "\e90e";
}
.icon-google4:before {
  content: "\e90f";
}
.icon-googlesearchconsole:before {
  content: "\e900";
  color: #458cf5;
}
.icon-googlescholar:before {
  content: "\e901";
  color: #4885ed;
}
.icon-googlepodcasts:before {
  content: "\e902";
  color: #4285f4;
}
.icon-googleplay:before {
  content: "\e903";
  color: #607d8b;
}
.icon-googlepay:before {
  content: "\e904";
  color: #5f6368;
}
.icon-googlehangouts:before {
  content: "\e905";
  color: #0c9d58;
}
.icon-googledrive:before {
  content: "\e906";
  color: #4285f4;
}
.icon-googlechrome:before {
  content: "\e907";
  color: #4285f4;
}
.icon-googleanalytics:before {
  content: "\e908";
  color: #ffc107;
}
.icon-googleads:before {
  content: "\e909";
  color: #4285f4;
}
.icon-google1:before {
  content: "\e90a";
  color: #4285f4;
}
.icon-google:before {
  content: "\ea88";
}
.icon-google2:before {
  content: "\ea89";
}
.icon-google3:before {
  content: "\ea8a";
}
.icon-google-plus:before {
  content: "\ea8b";
}
.icon-google-plus2:before {
  content: "\ea8c";
}
.icon-google-plus3:before {
  content: "\ea8d";
}
.icon-google-drive:before {
  content: "\ea8f";
}
