.order {
  &-page {
    .table {
      thead {
        th:last-child {
          text-align: center;
        }
      }
    }
  }
}
