// font mixins

@mixin jumbo-h1-heavy {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
}

@mixin jumbo-h1-light {
  font-weight: 400;
  font-size: 60px;
  line-height: 72px;
}
@mixin display-h1-heavy {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
}

@mixin display-h1-light {
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
}

@mixin headline-heavy {
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
}

@mixin headline-light {
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
}

@mixin title-heavy-h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4em;
}

@mixin title-light-h3 {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

@mixin subheader-heavy-h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

@mixin subheader-light-h4 {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

@mixin body-heavy {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

@mixin body-light {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

@mixin caption-heavy {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

@mixin caption-light {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

@mixin small-heavy {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
}

@mixin small-light {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

// mobile fonts
@mixin mobile-jumbo-h1-heavy {
  font-weight: 700;
  font-size: 34px;
  line-height: 1.2;
}

@mixin mobile-jumbo-h1-light {
  font-weight: 400;
  font-size: 34px;
  line-height: 1.2;
}
@mixin mobile-display-h1-heavy {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2;
}

@mixin mobile-display-h1-light {
  font-weight: 400;
  font-size: 30px;
  line-height: 1.2;
}

@mixin mobile-headline-heavy {
  font-weight: 700;
  font-size: 26px;
  line-height: 1.2;
}

@mixin mobile-headline-light {
  font-weight: 400;
  font-size: 26px;
  line-height: 1.2;
}

@mixin mobile-title-heavy-h3 {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.2;
}

@mixin mobile-title-light-h3 {
  font-weight: 400;
  font-size: 22px;
  line-height: 1.2;
}

@mixin mobile-subheader-heavy-h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
}

@mixin mobile-subheader-light-h4 {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
}

@mixin mobile-body-heavy {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

@mixin mobile-body-light {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

@mixin mobile-caption-heavy {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

@mixin mobile-caption-light {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

@mixin mobile-small-heavy {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
}

@mixin mobile-small-light {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

// utility mixins
@mixin border-rad($param) {
  border-radius: $param;
  -ms-border-radius: $param;
  -moz-border-radius: $param;
  -o-border-radius: $param;
  -webkit-border-radius: $param;
}

@mixin width($param) {
  // width: $param;
}

@mixin button-color($color) {
  background-color: $color;
  border-color: $color;
}

@mixin generalFont($color) {
  font-family: $font-family !important;
  color: $color !important;
  font-weight: 500;
}

@mixin widthing($param) {
  // width: $param * 1%;
}

@mixin min-widthing($param) {
  width: $param * 1%;
}

@mixin box-shadow($offens, $offset, $blur, $color, $inset) {
  box-shadow: $offens $offset $blur $color !important;
  -ms-box-shadow: $offens $offset $blur $color;
  -o-box-shadow: $offens $offset $blur $color;
  -webkit-box-shadow: $offens $offset $blur $color;
  -moz-box-shadow: $offens $offset $blur $color;
}

@mixin bordered($top, $bottom, $rigth, $left) {
  border-top-width: $top;
  border-bottom-width: $bottom;
  border-left-width: $left;
  border-right-width: $rigth;
}

@mixin fontColor($color) {
  color: $color !important;
}

@mixin backgroundColor($color) {
  background-color: $color;
}

@mixin responsive($query) {
  @media (max-width: $query) {
    @content;
  }
}

@mixin responsiveMin($query) {
  @media (min-width: $query) {
    @content;
  }
}

@mixin responsiveMin($query) {
  @media (min-width: $query) {
    @content;
  }
}

@mixin screenresponsiveMax($query) {
  @media only screen and (max-width: $query) {
    @content;
  }
}

@mixin font-weight($weight) {
  font-weight: $weight !important;
}

@mixin background-gradient() {
  .bg-account {
    background-image: none !important;
    background: radial-gradient(
      309.45% 136% at 50% -41.94%,
      #eae9eb 12.05%,
      #028c80 92.58%
    ) !important;

    box-shadow: 5px 8px 16px rgba(0, 0, 0, 0.18);
    border-radius: 22px;
    &::before {
      background-image: none !important;
      background: radial-gradient(
        309.45% 136% at 50% -41.94%,
        #eae9eb 12.05%,
        #028c80 92.58%
      ) !important;

      box-shadow: 5px 8px 16px rgba(0, 0, 0, 0.18);
      border-radius: 22px;
    }
  }
}
