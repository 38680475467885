.table {
  border: none;

  thead {
    th {
      border: none;
      @include caption-heavy;
      color: $giftano-gray-350;
    }
  }
  tbody {
    td {
      border-top: 1px solid;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid;
      border-color: $giftano-semiwhite;
      color: $giftano-gray-200;
      @include body-heavy;
      position: relative;
      vertical-align: middle;
    }
    .dt {
      &-type-primary {
        color: $giftano-lightblue !important;
        background-color: $giftano-primary;
        border-radius: $giftano-radius;
        padding: 6px 18px;
        position: absolute;
        top: 0;
        bottom: 0;
        height: 45%;
        margin: auto;
        @include caption-heavy;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      }
      &-type-secondary {
        color: white !important;
        background-color: $giftano-secondary;
        border-radius: $giftano-radius;
        padding: 6px 18px;
        position: absolute;
        top: 0;
        bottom: 0;
        height: 45%;
        margin: auto;
        @include caption-heavy;
      }
      &-type-info {
        color: $giftano-darkpurple !important;
        background-color: $giftano-lightblue;
        border-radius: $giftano-radius;
        padding: 6px 18px;
        position: absolute;
        top: 0;
        bottom: 0;
        height: 45%;
        margin: auto;
        @include caption-heavy;
      }
      &-date-primary {
        @include caption-heavy;
        color: $giftano-primary !important;
      }
      &-status-primary {
        color: $giftano-primary !important;
        text-transform: capitalize;
      }
      &-status-secondary {
        color: $giftano-secondary !important;
        text-transform: capitalize;
      }
      &-status-danger {
        color: $danger-color !important;
        text-transform: capitalize;
      }
      &-multi-list-primary {
        list-style: none;
        padding-left: 0;
        margin-top: 1em;
        @include caption-heavy;
        color: $giftano-primary;
        li {
          margin-bottom: 6px;
        }
      }
      &-multi-list-info {
        list-style: none;
        padding-left: 0;
        margin-top: 1em;
        @include caption-heavy;
        color: $giftano-info;
        li {
          margin-bottom: 6px;
        }
      }
      &-multi-list-warning {
        list-style: none;
        padding-left: 0;
        margin-top: 1em;
        @include caption-heavy;
        color: $giftano-secondary;
        li {
          margin-bottom: 6px;
        }
      }
    }
  }
}

.search-box {
  margin-top: 10px;
  margin-bottom: 10px;
}

.contact-list {
  .table {
    table-layout: auto !important;
  }
}

@include responsive($breakpoint-phone) {
  .table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    word-wrap: break-word;
    white-space: nowrap;
  }
}

@media (min-width: $breakpoint-tablet) {
  .table {
    display: inline-table;
  }
}

@include responsive($breakpoint-tablet) {
}
