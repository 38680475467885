@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import '../fonts/icons/style.css';
* {
  font-family: "Lato";
}

html {
  min-width: 100%;
  height: 100%;
}

body {
  background: white !important;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  position: relative;
}

#root {
  height: 100%;
}

:root {
  --gf-neutral-50: #fff;
  --gf-neutral-100: #f9f9f9;
  --gf-neutral-200: #ececed;
  --gf-neutral-300: #c4c4c4;
  --gf-neutral-400: #5c5c5c;
  --gf-neutral-500: #000;
  --gf-primary-50: #e6f4f2;
  --gf-primary-100: #9ad1cc;
  --gf-primary-200: #4eafa6;
  --gf-primary-300: #028c80;
  --gf-primary-400: #027066;
  --gf-primary-500: #01544d;
  --gf-error-50: #fef3f2;
  --gf-error-100: #fee4e2;
  --gf-error-200: #fda29b;
  --gf-error-300: #f04438;
  --gf-error-400: #b42318;
  --gf-error-500: #7a271a;
  --gf-warning-50: #fef4e6;
  --gf-warning-100: #fcd39d;
  --gf-warning-200: #f9b153;
  --gf-warning-300: #f79009;
  --gf-warning-400: #c67307;
  --gf-warning-500: #633a04;
  --gf-success-50: #e7f8f0;
  --gf-success-100: #a0e2c3;
  --gf-success-200: #59cd97;
  --gf-success-300: #12b76a;
  --gf-success-400: #0d804a;
  --gf-success-500: #053720;
  --gf-dark-violet-50: #eae9eb;
  --gf-dark-violet-100: #827c89;
  --gf-dark-violet-200: #443a4e;
  --gf-dark-violet-300: #2f243a;
  --gf-dark-violet-400: #2a2034;
  --gf-dark-violet-500: #211929;
  --gf-soft-orange-50: #fff5ed;
  --gf-soft-orange-100: #fed8b9;
  --gf-soft-orange-200: #fdbb84;
  --gf-soft-orange-300: #fc9e4f;
  --gf-soft-orange-400: #ca7e3f;
  --gf-soft-orange-500: #975f2f;
  --gf-light-gray-50: #fbfefe;
  --gf-light-gray-100: #f0faf9;
  --gf-light-gray-200: #e5f7f5;
  --gf-light-gray-300: #daf3f1;
  --gf-light-gray-400: #aec2c1;
  --gf-light-gray-500: #839291;
  --gf-light-gray-orange-50: #fdfbf9;
  --gf-light-gray-orange-100: #f9f0e7;
  --gf-light-gray-orange-200: #f4e5d5;
  --gf-light-gray-orange-300: #efdac3;
  --gf-light-gray-orange-400: #bfae9c;
  --gf-light-gray-orange-500: #8f8375;
  --gf-dark-red-50: #f7eae6;
  --gf-dark-red-100: #dfaa9a;
  --gf-dark-red-200: #c76a4e;
  --gf-dark-red-300: #af2a02;
  --gf-dark-red-400: #8c2202;
  --gf-dark-red-500: #7a1d01;
  --gf-violet-50: #eee6f7;
  --gf-violet-100: #b99adf;
  --gf-violet-200: #854ec7;
  --gf-violet-300: #5002af;
  --gf-violet-400: #40028c;
  --gf-violet-500: #300169;
  --gf-primary-color: var(--gf-primary-300);
  --gf-error-color: var(--gf-error-300);
  --gf-warning-color: var(--gf-warning-300);
  --gf-neutral-color: var(--gf-neutral-300);
  --gf-success-color: var(--gf-success-300);
  /**
  * @tokens Easings
  * @presenter Easing
  */
  --gf-easing-swift: cubic-bezier(0.55, 0, 0.1, 1);
  /**
  * @tokens Font Families
  * @presenter FontFamily
  */
  --base-font-family: "Lato", sans-serif;
  --mono-font-family: Monaco, monospace;
  --web-font-family: "Lato";
  /**
  * @tokens Font Sizes
  * @presenter FontSize
  */
  --gf-fontsize-12: 12px;
  --gf-fontsize-14: 14px;
  --gf-fontsize-16: 16px;
  --gf-fontsize-18: 18px;
  --gf-fontsize-20: 20px;
  --gf-fontsize-23: 23px;
  --gf-fontsize-26: 26px;
  --gf-fontsize-29: 29px;
  --gf-fontsize-32: 32px;
  --gf-fontsize-36: 36px;
  --gf-fontsize-41: 41px;
  --gf-fontsize-46: 46px;
  /**
  * @tokens Font Weights
  * @presenter FontWeight
  */
  --gf-font-weight-regular: 400;
  --gf-font-weight-semibold: 500;
  --gf-font-weight-bold: 600;
  --gf-font-weight-extrabold: 800;
  /**
  * @tokens Line Heights
  * @presenter LineHeight
  */
  --gf-line-height: 1.5;
  /**
  * @tokens Letter Spacings
  * @presenter LetterSpacing
  */
  --gf-letter-spacing-none: 0;
  --gf-letter-spacing-s: 1px;
  --gf-letter-spacing-m: 2px;
  /**
  * @tokens Opacities
  * @presenter Opacity
  */
  --gf-opacity: 0.5;
  /**
  * @tokens Animations
  * @presenter Animation
  */
  --gf-animation-rotate: rotate 1.2s infinite cubic-bezier(0.55, 0, 0.1, 1);
  /**
  * @tokens Border Radius
  * @presenter BorderRadius
  */
  --gf-border-radius-s: 2px;
  --gf-border-radius-m: 4px;
  --gf-border-radius-l: 30px;
  /**
  * @tokens Borders
  * @presenter Border
  */
  --gf-border-normal: 1px solid var(--neutral-300);
  --gf-border-active: 1px solid var(--neutral-400);
  /**
  * @tokens Shadows
  * @presenter Shadow
  */
  --gf-shadow-thin: 0 4px 20px rgba(125, 125, 125, 0.1);
  --gf-shadow-normal: 0 10px 20px -10px rgba(0, 0, 0, 0.25);
  --gf-shadow-thick: 0 4px 20px rgba(125, 125, 125, 0.25);
  /**
  * @tokens Spacings
  * @presenter Spacing
  */
  --gf-gf-spacing-s: 8px;
  --gf-gf-spacing-m: 12px;
  --gf-gf-spacing-l: 16px;
  /**
  * @tokens Z-Index
  */
  --gf-z-index: 1000;
}

:root {
  --toastify-icon-color-error: #fff;
  --toastify-icon-color-success: #fff;
  --toastify-icon-color-error: #fff;
}

.vertical-space {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text-titile-app {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.4em;
  min-height: 3em;
}

.text-black {
  color: #000 !important;
  font-weight: 600;
}

.text-orange {
  color: var(--gf-warning-300) !important;
}

.text-gf-primary {
  color: var(--gf-primary-300);
}
.text-gf-primary:hover {
  color: var(--gf-primary-400);
}

.text-gf-neutral {
  color: var(--gf-neutral-400);
}
.text-gf-neutral:hover {
  color: var(--gf-neutral-500) !important;
}

.Toastify__toast-icon {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 10px;
}

.Toastify__close-button {
  color: #fff !important;
}

.Toastify__toast-body {
  width: 100%;
}

@media (max-width: 767px) {
  .text-titile-app {
    font-size: 12px;
    font-weight: 400;
  }
}
:root {
  --gf-neutral-50: #fff;
  --gf-neutral-100: #f9f9f9;
  --gf-neutral-200: #ececed;
  --gf-neutral-300: #c4c4c4;
  --gf-neutral-400: #5c5c5c;
  --gf-neutral-500: #000;
  --gf-primary-50: #e6f4f2;
  --gf-primary-100: #9ad1cc;
  --gf-primary-200: #4eafa6;
  --gf-primary-300: #028c80;
  --gf-primary-400: #027066;
  --gf-primary-500: #01544d;
  --gf-error-50: #fef3f2;
  --gf-error-100: #fee4e2;
  --gf-error-200: #fda29b;
  --gf-error-300: #f04438;
  --gf-error-400: #b42318;
  --gf-error-500: #7a271a;
  --gf-warning-50: #fef4e6;
  --gf-warning-100: #fcd39d;
  --gf-warning-200: #f9b153;
  --gf-warning-300: #f79009;
  --gf-warning-400: #c67307;
  --gf-warning-500: #633a04;
  --gf-success-50: #e7f8f0;
  --gf-success-100: #a0e2c3;
  --gf-success-200: #59cd97;
  --gf-success-300: #12b76a;
  --gf-success-400: #0d804a;
  --gf-success-500: #053720;
  --gf-dark-violet-50: #eae9eb;
  --gf-dark-violet-100: #827c89;
  --gf-dark-violet-200: #443a4e;
  --gf-dark-violet-300: #2f243a;
  --gf-dark-violet-400: #2a2034;
  --gf-dark-violet-500: #211929;
  --gf-soft-orange-50: #fff5ed;
  --gf-soft-orange-100: #fed8b9;
  --gf-soft-orange-200: #fdbb84;
  --gf-soft-orange-300: #fc9e4f;
  --gf-soft-orange-400: #ca7e3f;
  --gf-soft-orange-500: #975f2f;
  --gf-light-gray-50: #fbfefe;
  --gf-light-gray-100: #f0faf9;
  --gf-light-gray-200: #e5f7f5;
  --gf-light-gray-300: #daf3f1;
  --gf-light-gray-400: #aec2c1;
  --gf-light-gray-500: #839291;
  --gf-light-gray-orange-50: #fdfbf9;
  --gf-light-gray-orange-100: #f9f0e7;
  --gf-light-gray-orange-200: #f4e5d5;
  --gf-light-gray-orange-300: #efdac3;
  --gf-light-gray-orange-400: #bfae9c;
  --gf-light-gray-orange-500: #8f8375;
  --gf-dark-red-50: #f7eae6;
  --gf-dark-red-100: #dfaa9a;
  --gf-dark-red-200: #c76a4e;
  --gf-dark-red-300: #af2a02;
  --gf-dark-red-400: #8c2202;
  --gf-dark-red-500: #7a1d01;
  --gf-violet-50: #eee6f7;
  --gf-violet-100: #b99adf;
  --gf-violet-200: #854ec7;
  --gf-violet-300: #5002af;
  --gf-violet-400: #40028c;
  --gf-violet-500: #300169;
  --gf-primary-color: var(--gf-primary-300);
  --gf-error-color: var(--gf-error-300);
  --gf-warning-color: var(--gf-warning-300);
  --gf-neutral-color: var(--gf-neutral-300);
  --gf-success-color: var(--gf-success-300);
  /**
  * @tokens Easings
  * @presenter Easing
  */
  --gf-easing-swift: cubic-bezier(0.55, 0, 0.1, 1);
  /**
  * @tokens Font Families
  * @presenter FontFamily
  */
  --base-font-family: "Lato", sans-serif;
  --mono-font-family: Monaco, monospace;
  --web-font-family: "Lato";
  /**
  * @tokens Font Sizes
  * @presenter FontSize
  */
  --gf-fontsize-12: 12px;
  --gf-fontsize-14: 14px;
  --gf-fontsize-16: 16px;
  --gf-fontsize-18: 18px;
  --gf-fontsize-20: 20px;
  --gf-fontsize-23: 23px;
  --gf-fontsize-26: 26px;
  --gf-fontsize-29: 29px;
  --gf-fontsize-32: 32px;
  --gf-fontsize-36: 36px;
  --gf-fontsize-41: 41px;
  --gf-fontsize-46: 46px;
  /**
  * @tokens Font Weights
  * @presenter FontWeight
  */
  --gf-font-weight-regular: 400;
  --gf-font-weight-semibold: 500;
  --gf-font-weight-bold: 600;
  --gf-font-weight-extrabold: 800;
  /**
  * @tokens Line Heights
  * @presenter LineHeight
  */
  --gf-line-height: 1.5;
  /**
  * @tokens Letter Spacings
  * @presenter LetterSpacing
  */
  --gf-letter-spacing-none: 0;
  --gf-letter-spacing-s: 1px;
  --gf-letter-spacing-m: 2px;
  /**
  * @tokens Opacities
  * @presenter Opacity
  */
  --gf-opacity: 0.5;
  /**
  * @tokens Animations
  * @presenter Animation
  */
  --gf-animation-rotate: rotate 1.2s infinite cubic-bezier(0.55, 0, 0.1, 1);
  /**
  * @tokens Border Radius
  * @presenter BorderRadius
  */
  --gf-border-radius-s: 2px;
  --gf-border-radius-m: 4px;
  --gf-border-radius-l: 30px;
  /**
  * @tokens Borders
  * @presenter Border
  */
  --gf-border-normal: 1px solid var(--neutral-300);
  --gf-border-active: 1px solid var(--neutral-400);
  /**
  * @tokens Shadows
  * @presenter Shadow
  */
  --gf-shadow-thin: 0 4px 20px rgba(125, 125, 125, 0.1);
  --gf-shadow-normal: 0 10px 20px -10px rgba(0, 0, 0, 0.25);
  --gf-shadow-thick: 0 4px 20px rgba(125, 125, 125, 0.25);
  /**
  * @tokens Spacings
  * @presenter Spacing
  */
  --gf-gf-spacing-s: 8px;
  --gf-gf-spacing-m: 12px;
  --gf-gf-spacing-l: 16px;
  /**
  * @tokens Z-Index
  */
  --gf-z-index: 1000;
}

:root {
  --toastify-icon-color-error: #fff;
  --toastify-icon-color-success: #fff;
  --toastify-icon-color-error: #fff;
}

.vertical-space {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text-titile-app {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.4em;
  min-height: 3em;
}

.text-black {
  color: #000 !important;
  font-weight: 600;
}

.text-orange {
  color: var(--gf-warning-300) !important;
}

.text-gf-primary {
  color: var(--gf-primary-300);
}
.text-gf-primary:hover {
  color: var(--gf-primary-400);
}

.text-gf-neutral {
  color: var(--gf-neutral-400);
}
.text-gf-neutral:hover {
  color: var(--gf-neutral-500) !important;
}

.Toastify__toast-icon {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 10px;
}

.Toastify__close-button {
  color: #fff !important;
}

.Toastify__toast-body {
  width: 100%;
}

@media (max-width: 767px) {
  .text-titile-app {
    font-size: 12px;
    font-weight: 400;
  }
}
.card {
  box-shadow: none;
  border-radius: 0;
  border: none;
}
.card-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4em;
  color: black !important;
  margin-bottom: 1em;
}
.card-subtitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: black;
  margin-bottom: 1em;
}
.card-subheader {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}
.card-paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #4f4f4f;
}
.card-paragraph-action {
  margin-top: 0.5em;
}
.card-inner {
  border: 1px solid #e5eaee;
  border-radius: 6px;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
.card-body {
  margin-bottom: 15px;
}

.widget-card {
  margin-top: 20px;
  margin-bottom: 35px;
  padding: 10px 15px;
}

@media only screen and (max-width: 767px) {
  .card {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    height: 100%;
    max-width: 100% !important;
    position: relative !important;
    margin-top: 1em !important;
  }
  .card-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.2;
  }
  .card-subtitle {
    font-weight: 400;
    font-size: 22px;
    line-height: 1.2;
  }
  .card-subheader {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
  }
  .card-paragraph {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }
  .widget-card {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .portal-page .card {
    position: absolute !important;
    max-height: 75% !important;
    max-width: 700px !important;
    margin-top: auto !important;
    left: 15px !important;
    right: 15px !important;
  }
}
@media (max-width: 623px) {
  .portal-page .card {
    position: absolute !important;
    max-height: max-content !important;
    height: max-content;
  }
}
.auth-logo {
  width: auto;
  height: 30px;
  height: intrinsic;
  object-fit: contain;
}
.auth-version {
  color: white !important;
}

#appleid-signin {
  border-radius: 2em;
  overflow: hidden;
}
#appleid-signin > div {
  width: 100% !important;
  max-width: 100% !important;
}

.logout-logo {
  width: 15em;
  height: intrinsic;
  margin-bottom: 2rem;
}

.forgot-logo {
  width: 15em;
  height: intrinsic;
  margin-bottom: 2rem;
}

.portal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
}
.portal-container > a img {
  margin-bottom: 1em;
}
.portal-container .profile-scaffold {
  background-color: var(--gf-dark-violet-300);
  border-radius: var(--gf-border-radius-m);
  position: relative;
  text-align: center;
  min-height: 450px;
}
.portal-container .profile-scaffold__img {
  height: 100px;
  width: 100px;
  text-align: center;
  background-color: var(--gf-neutral-300);
  margin: 1em auto;
  display: flex;
  /* position: absolute; */
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.portal-container .profile-scaffold img {
  position: absolute;
  bottom: 20px;
  left: 0;
  max-width: 70%;
  right: 0;
  margin: 0 auto;
}

.portal-block.protal-loading div.loading {
  height: 150px;
  background-color: rgba(0, 0, 0, 0.2);
  animation: skeleton-anim 1s 0.5s infinite alternate;
}
.portal-block.protal-loading p.loading {
  background-color: rgba(0, 0, 0, 0.2);
  height: 30px;
  animation: skeleton-anim 1s 0.5s infinite alternate;
}

.pin-input {
  width: 40px;
}

.auth-page .login-block {
  position: absolute;
  right: 0;
  height: 100%;
}

.login-block .error-page .login-container {
  padding: 0;
  height: 100%;
}

.default-page .error-page {
  height: 100%;
  position: absolute;
  right: 0;
  width: 50%;
}

.error-page .login-container .card {
  height: 100%;
}

.error-page .login-container .card-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.auth-page .profile-scaffold {
  display: none;
}

.auth-page .reset-password-page .profile-form {
  width: 100% !important;
}

.default-page .reset-password-page {
  margin-top: 10%;
}
.default-page .reset-password-page .card-body {
  padding-top: 5px;
  padding-bottom: 0;
}
.default-page .reset-password-page .profile-form {
  margin-top: 10px;
  padding: 2em 4em;
}
.default-page .reset-password-page .profile-scaffold {
  background-color: var(--gf-dark-violet-300);
  border-radius: var(--gf-border-radius-m);
  position: relative;
  text-align: center;
  min-height: 450px;
}
.default-page .reset-password-page .profile-scaffold__img {
  height: 100px;
  width: 100px;
  text-align: center;
  background-color: var(--gf-neutral-300);
  margin: 1em auto;
  display: flex;
  /* position: absolute; */
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.default-page .reset-password-page .profile-scaffold img {
  position: absolute;
  bottom: 20px;
  left: 0;
  max-width: 70%;
  right: 0;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .layoutWrapper {
    padding: 0;
  }
  .portal-page .login-container .card {
    max-width: 100% !important;
    width: 100%;
    left: 0 !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  .portal-container {
    padding-left: 0;
    padding-right: 0;
  }
  .text-sm-center {
    text-align: center;
    font-size: 14px;
  }
  .default-page .error-page {
    height: 60%;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 95%;
    left: 0;
    right: 0;
  }
  .login-container .profile-name {
    font-size: 16px !important;
  }
  .portal-container .profile-scaffold {
    display: flex;
    min-height: auto;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .default-page .reset-password-page .profile-scaffold {
    min-height: auto !important;
  }
  .default-page .reset-password-page .login-container .card {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  .default-page .reset-password-page .portal-container {
    padding-top: 0;
    margin-top: -15px !important;
  }
  .default-page .reset-password-page .profile-form {
    min-height: calc(100vh - 107px);
  }
  .auth-page .reset-password-page .portal-container {
    padding: 1rem;
  }
  .auth-page .reset-password-page .portal-container .profile-scaffold {
    display: none;
  }
  .default-page .reset-password-page {
    margin-top: 0;
  }
  .portal-container .profile-scaffold img {
    left: auto;
    right: 25px;
    width: 40px;
    height: 40px;
    margin: auto;
    top: 20px;
  }
  .default-page .reset-password-page .profile-scaffold img {
    left: auto;
    right: 25px;
    margin: auto;
  }
  .portal-container .profile-scaffold__img {
    height: 40px;
    width: 40px;
    text-align: left;
    margin-left: 15px;
    margin-right: 15px;
  }
  .portal-container .profile-scaffold__img svg {
    width: 1em;
    height: 1em;
  }
  .default-page .reset-password-page .portal-container .profile-scaffold__img {
    height: 40px !important;
    width: 40px !important;
    text-align: left !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}
/* Safari 10.1+ (alternate method) */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .auth-logo {
      max-width: 75%;
    }
  }
}
@-moz-document url-prefix() {
  @media (min-width: 1024px) {
    .portal-page .login-container .card {
      max-height: 85% !important;
    }
  }
}
@media (min-width: 624px) and (max-width: 767px) {
  .layoutWrapper {
    padding: 0;
  }
  .portal-page .login-container .card {
    max-width: 100% !important;
    width: 100%;
    left: 0 !important;
    padding-top: 0 !important;
    position: relative !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .portal-container {
    padding-left: 0;
    padding-right: 0;
  }
  .text-sm-center {
    text-align: center;
    font-size: 14px;
  }
  .default-page .error-page {
    height: 60%;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 95%;
    left: 0;
    right: 0;
  }
  .login-container .profile-name {
    font-size: 16px !important;
    margin-top: 25px !important;
  }
  .portal-container .profile-scaffold {
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .portal-container .profile-scaffold img {
    left: auto;
    right: 25px;
    width: 40px;
    height: 40px;
    margin: auto;
    top: 20px;
  }
  .portal-container .profile-scaffold__img {
    height: 40px;
    width: 40px;
    text-align: left;
    margin-left: 15px;
    margin-right: 15px;
  }
  .portal-container .profile-scaffold__img svg {
    width: 1em;
    height: 1em;
  }
}
@keyframes skeleton-anim {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}
input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 16px; /* Firefox */
  color: var(--gf-neutral-400);
  opacity: 1;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 16px; /* Firefox */
  color: var(--gf-neutral-400);
  opacity: 1;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: 16px; /* Firefox */
  color: var(--gf-neutral-400);
  opacity: 1;
}

.pin-input {
  width: 40px;
}

.notice {
  color: var(--gf-soft-orange-200);
  font-size: 14px;
}

.form-subtitle {
  font-size: 16px;
  font-weight: 600;
}

.form-input-otp .form-control {
  height: 40px;
  padding: 5px;
  text-align: center;
  min-width: 2em;
}

.form-group {
  position: relative;
}
.form-group h4 {
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
}
.form-group p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}
.form-group label {
  width: 100%;
  font-size: 14px;
}
.form-group input,
.form-group textarea {
  border: 1px solid transparent !important;
  border-radius: 6px !important;
  background-color: #f7f7f7 !important;
}
.form-label {
  color: #4f4f4f;
  font-size: 16px;
}
.form-description {
  color: #828282;
}

.form-control {
  height: 40px;
}
.form-control:focus {
  border-color: var(--gf-primary-400) !important;
  box-shadow: none !important;
}

.container-check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 3px;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2px solid #2e3a59;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #2e3a59;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.react-tel-input .form-control {
  width: 100% !important;
}

.MuiInputBase-input {
  border: 1px solid transparent !important;
  border-radius: 6px !important;
  background-color: #f7f7f7 !important;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 28px !important;
  padding: 6px !important;
  width: 100%;
  outline: none;
  padding-left: 0.75rem !important;
}

.MuiInput-underline:after,
.MuiInput-underline:before {
  content: none !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root] {
  padding-right: 39px !important;
}

.MuiAutocomplete-clearIndicator {
  margin-right: 10px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  padding: 0 !important;
}

.MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root] {
  padding: 0 !important;
}

.MuiInputAdornment-root p {
  border: 1px solid transparent !important;
  border-radius: 6px !important;
  background-color: #f7f7f7 !important;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0.375rem 0.75rem;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 40px;
  width: 100%;
  outline: none;
  margin-right: 10px;
}

.MuiInput-underline:before {
  border-bottom: none;
}
.MuiInput-underline:after {
  border-bottom: none;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none;
}

.MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root] {
  padding: 0;
}
.MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root] .MuiAutocomplete-endAdornment {
  right: 0;
}

.MuiAutocomplete-root,
.MuiInput-root {
  margin-bottom: 1rem;
}

.MuiAutocomplete-inputRoot {
  margin-bottom: 1rem;
}

.special-label {
  display: none;
}

:root {
  --gf-neutral-50: #fff;
  --gf-neutral-100: #f9f9f9;
  --gf-neutral-200: #ececed;
  --gf-neutral-300: #c4c4c4;
  --gf-neutral-400: #5c5c5c;
  --gf-neutral-500: #000;
  --gf-primary-50: #e6f4f2;
  --gf-primary-100: #9ad1cc;
  --gf-primary-200: #4eafa6;
  --gf-primary-300: #028c80;
  --gf-primary-400: #027066;
  --gf-primary-500: #01544d;
  --gf-error-50: #fef3f2;
  --gf-error-100: #fee4e2;
  --gf-error-200: #fda29b;
  --gf-error-300: #f04438;
  --gf-error-400: #b42318;
  --gf-error-500: #7a271a;
  --gf-warning-50: #fef4e6;
  --gf-warning-100: #fcd39d;
  --gf-warning-200: #f9b153;
  --gf-warning-300: #f79009;
  --gf-warning-400: #c67307;
  --gf-warning-500: #633a04;
  --gf-success-50: #e7f8f0;
  --gf-success-100: #a0e2c3;
  --gf-success-200: #59cd97;
  --gf-success-300: #12b76a;
  --gf-success-400: #0d804a;
  --gf-success-500: #053720;
  --gf-dark-violet-50: #eae9eb;
  --gf-dark-violet-100: #827c89;
  --gf-dark-violet-200: #443a4e;
  --gf-dark-violet-300: #2f243a;
  --gf-dark-violet-400: #2a2034;
  --gf-dark-violet-500: #211929;
  --gf-soft-orange-50: #fff5ed;
  --gf-soft-orange-100: #fed8b9;
  --gf-soft-orange-200: #fdbb84;
  --gf-soft-orange-300: #fc9e4f;
  --gf-soft-orange-400: #ca7e3f;
  --gf-soft-orange-500: #975f2f;
  --gf-light-gray-50: #fbfefe;
  --gf-light-gray-100: #f0faf9;
  --gf-light-gray-200: #e5f7f5;
  --gf-light-gray-300: #daf3f1;
  --gf-light-gray-400: #aec2c1;
  --gf-light-gray-500: #839291;
  --gf-light-gray-orange-50: #fdfbf9;
  --gf-light-gray-orange-100: #f9f0e7;
  --gf-light-gray-orange-200: #f4e5d5;
  --gf-light-gray-orange-300: #efdac3;
  --gf-light-gray-orange-400: #bfae9c;
  --gf-light-gray-orange-500: #8f8375;
  --gf-dark-red-50: #f7eae6;
  --gf-dark-red-100: #dfaa9a;
  --gf-dark-red-200: #c76a4e;
  --gf-dark-red-300: #af2a02;
  --gf-dark-red-400: #8c2202;
  --gf-dark-red-500: #7a1d01;
  --gf-violet-50: #eee6f7;
  --gf-violet-100: #b99adf;
  --gf-violet-200: #854ec7;
  --gf-violet-300: #5002af;
  --gf-violet-400: #40028c;
  --gf-violet-500: #300169;
  --gf-primary-color: var(--gf-primary-300);
  --gf-error-color: var(--gf-error-300);
  --gf-warning-color: var(--gf-warning-300);
  --gf-neutral-color: var(--gf-neutral-300);
  --gf-success-color: var(--gf-success-300);
  /**
  * @tokens Easings
  * @presenter Easing
  */
  --gf-easing-swift: cubic-bezier(0.55, 0, 0.1, 1);
  /**
  * @tokens Font Families
  * @presenter FontFamily
  */
  --base-font-family: "Lato", sans-serif;
  --mono-font-family: Monaco, monospace;
  --web-font-family: "Lato";
  /**
  * @tokens Font Sizes
  * @presenter FontSize
  */
  --gf-fontsize-12: 12px;
  --gf-fontsize-14: 14px;
  --gf-fontsize-16: 16px;
  --gf-fontsize-18: 18px;
  --gf-fontsize-20: 20px;
  --gf-fontsize-23: 23px;
  --gf-fontsize-26: 26px;
  --gf-fontsize-29: 29px;
  --gf-fontsize-32: 32px;
  --gf-fontsize-36: 36px;
  --gf-fontsize-41: 41px;
  --gf-fontsize-46: 46px;
  /**
  * @tokens Font Weights
  * @presenter FontWeight
  */
  --gf-font-weight-regular: 400;
  --gf-font-weight-semibold: 500;
  --gf-font-weight-bold: 600;
  --gf-font-weight-extrabold: 800;
  /**
  * @tokens Line Heights
  * @presenter LineHeight
  */
  --gf-line-height: 1.5;
  /**
  * @tokens Letter Spacings
  * @presenter LetterSpacing
  */
  --gf-letter-spacing-none: 0;
  --gf-letter-spacing-s: 1px;
  --gf-letter-spacing-m: 2px;
  /**
  * @tokens Opacities
  * @presenter Opacity
  */
  --gf-opacity: 0.5;
  /**
  * @tokens Animations
  * @presenter Animation
  */
  --gf-animation-rotate: rotate 1.2s infinite cubic-bezier(0.55, 0, 0.1, 1);
  /**
  * @tokens Border Radius
  * @presenter BorderRadius
  */
  --gf-border-radius-s: 2px;
  --gf-border-radius-m: 4px;
  --gf-border-radius-l: 30px;
  /**
  * @tokens Borders
  * @presenter Border
  */
  --gf-border-normal: 1px solid var(--neutral-300);
  --gf-border-active: 1px solid var(--neutral-400);
  /**
  * @tokens Shadows
  * @presenter Shadow
  */
  --gf-shadow-thin: 0 4px 20px rgba(125, 125, 125, 0.1);
  --gf-shadow-normal: 0 10px 20px -10px rgba(0, 0, 0, 0.25);
  --gf-shadow-thick: 0 4px 20px rgba(125, 125, 125, 0.25);
  /**
  * @tokens Spacings
  * @presenter Spacing
  */
  --gf-gf-spacing-s: 8px;
  --gf-gf-spacing-m: 12px;
  --gf-gf-spacing-l: 16px;
  /**
  * @tokens Z-Index
  */
  --gf-z-index: 1000;
}

:root {
  --toastify-icon-color-error: #fff;
  --toastify-icon-color-success: #fff;
  --toastify-icon-color-error: #fff;
}

.vertical-space {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text-titile-app {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.4em;
  min-height: 3em;
}

.text-black {
  color: #000 !important;
  font-weight: 600;
}

.text-orange {
  color: var(--gf-warning-300) !important;
}

.text-gf-primary {
  color: var(--gf-primary-300);
}
.text-gf-primary:hover {
  color: var(--gf-primary-400);
}

.text-gf-neutral {
  color: var(--gf-neutral-400);
}
.text-gf-neutral:hover {
  color: var(--gf-neutral-500) !important;
}

.Toastify__toast-icon {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 10px;
}

.Toastify__close-button {
  color: #fff !important;
}

.Toastify__toast-body {
  width: 100%;
}

@media (max-width: 767px) {
  .text-titile-app {
    font-size: 12px;
    font-weight: 400;
  }
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
  background: transparent;
}

.btn {
  transition: 0.3s ease-in;
  height: 45px;
}
.btn-transparent {
  background-color: transparent;
  border: none;
}
.btn-transparent:hover {
  background-color: transparent;
  border: none;
}
.btn:focus {
  box-shadow: none !important;
}
.btn-back {
  letter-spacing: 1px;
  color: #000 !important;
  cursor: pointer;
  font-weight: 400;
}
.btn-facebook {
  display: block;
  border: 1px solid #fafafa !important;
  width: 100%;
  background-color: #1877f2;
  color: #fff !important;
  font-weight: bold;
  border-radius: 2em;
  transition: 0.3s ease-in background-color;
}
.btn-facebook:hover {
  background-color: #303f9f !important;
}
.btn-facebook:disabled {
  display: block;
  border: 1px solid #fafafa !important;
  width: 100%;
  background-color: #1877f2;
  color: #fff !important;
  font-weight: bold;
  border-radius: 2em;
  transition: 0.3s ease-in background-color;
}
.btn-apple {
  display: block;
  border: 1px solid #fafafa !important;
  width: 100%;
  background-color: var(--gf-neutral-500);
  color: #fff !important;
  font-weight: bold;
  border-radius: 2em;
  transition: 0.3s ease-in background-color;
}
.btn-cta-guest {
  text-decoration: underline;
  cursor: pointer;
  text-underline-offset: 6px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.btn-cta-guest:hover {
  text-decoration: underline;
  text-underline-offset: 6px;
}
.btn-fix-width {
  width: 15em;
  margin: 0 auto;
  text-decoration: none;
}
.btn-fix-width-sm {
  min-width: 12em;
}
.btn-pop {
  background-color: transparent;
  border: none;
}
.btn-pop:hover, .btn-pop:active, .btn-pop:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 1;
}
.btn-stretched {
  width: 96%;
  height: initial;
  text-decoration: none;
}
.btn-image {
  width: 100%;
  display: flex;
  min-height: 100px;
  height: auto;
  background-color: white;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16));
  align-content: center;
  align-items: center;
  transition: all 0.3s ease-in;
  flex-direction: column;
}
.btn-image:hover {
  background-color: var(--gf-light-gray-100);
  box-shadow: var(--gf-shadow-normal);
}
.btn-image:hover svg path {
  fill: white;
}
.btn-image:focus {
  background-color: var(--gf-warning-400);
}
.btn-image--home {
  background-color: var(--gf-primary-300);
}
.btn-image img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.btn-forgot-password {
  margin-top: -1.5em;
}
.btn svg {
  vertical-align: middle;
}

.btn-primary {
  background-color: var(--gf-primary-300);
  border-color: var(--gf-primary-300);
  font-family: "Lato", sans-serif !important;
  color: #fff !important;
  font-weight: 500;
  font-weight: 600;
  border: none;
  border-radius: 2em;
}
.btn-primary:hover {
  background-color: var(--gf-primary-400) !important;
  text-decoration: none;
}
.btn-primary:focus, .btn-primary:active {
  background-color: var(--gf-primary-400);
}
.btn-primary:disabled {
  background-color: #028c80;
}

.btn-warning {
  background-color: var(--gf-warning-300);
  border-color: var(--gf-warning-300);
  font-family: "Lato", sans-serif !important;
  color: #fff !important;
  font-weight: 500;
  border: none;
  border-radius: 2em;
}
.btn-warning:hover {
  background-color: var(--gf-warning-400);
  text-decoration: none;
}
.btn-warning:focus, .btn-warning:active {
  background-color: var(--gf-warning-300);
}

.btn-danger {
  background-color: var(--gf-error-300);
  border-color: var(--gf-error-300);
  font-family: "Lato", sans-serif !important;
  color: #fff !important;
  font-weight: 500;
  border: none;
  border-radius: 2em;
}
.btn-danger:hover {
  background-color: var(--gf-error-400);
  text-decoration: none;
}
.btn-danger:focus, .btn-danger:active {
  background-color: var(--gf-error-300);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--gf-primary-300);
  border-color: var(--gf-primary-300);
  font-family: "Lato", sans-serif !important;
  color: #fff !important;
  font-weight: 500;
  border: none;
}
.btn-primary:not(:disabled):not(.disabled).active:hover,
.btn-primary:not(:disabled):not(.disabled):active:hover,
.show > .btn-primary.dropdown-toggle:hover {
  background-color: var(--gf-primary-400);
}

.btn-info {
  background-color: var(--gf-violet-300);
  border-color: var(--gf-violet-300);
  font-family: "Lato", sans-serif !important;
  color: var(--gf-light-gray-300) !important;
  font-weight: 500;
  border: none;
  border-radius: 2em;
}
.btn-info:hover {
  background-color: var(--gf-violet-400);
}

.btn-outline-primary {
  background-color: var(--gf-light-gray-100);
  color: var(--gf-primary-300) !important;
  border: 1px solid var(--gf-primary-300);
  border-radius: 2em;
  padding: 0.375rem 0.75rem;
}
.btn-outline-primary svg {
  width: 0.6em;
  height: 0.6em;
  margin-bottom: 1px;
}
.btn-outline-primary:hover {
  border: 1px solid var(--gf-primary-400) !important;
  color: var(--gf-primary-400) !important;
  background-color: #f3f6f9 !important;
}
.btn-outline-primary img {
  color: var(--gf-primary-300) !important;
  margin-right: 8px;
  font-size: 20px;
  padding: 0;
}
.btn-outline-dark {
  background-color: #fff;
  color: #5c5c5c !important;
  border: 1px solid #5c5c5c;
  border-radius: 2em;
  padding: 0.375rem 0.75rem;
}
.btn-outline-dark svg {
  width: 0.6em;
  height: 0.6em;
  margin-bottom: 1px;
}
.btn-outline-dark:hover {
  border: 1px solid var(--gf-primary-400);
  color: var(--gf-primary-400) !important;
  background-color: #f3f6f9;
}
.btn-outline-dark img {
  color: #5c5c5c !important;
  margin-right: 8px;
  font-size: 20px;
  padding: 0;
}
.btn-outline-danger {
  background-color: #fff;
  color: var(--gf-error-300) !important;
  border: 1px solid var(--gf-error-300);
  border-radius: 2em;
  padding: 0.375rem 0.75rem;
  line-height: 1.6;
}
.btn-outline-danger svg {
  width: 0.6em;
  height: 0.6em;
  margin-bottom: 1px;
}
.btn-outline-danger:hover {
  border: 1px solid var(--gf-error-400);
  color: var(--gf-error-400) !important;
  background-color: #f3f6f9;
  box-shadow: 0px 0px 35px 0px rgba(181, 181, 195, 0.15);
}
.btn-outline-info {
  background-color: #fff;
  color: var(--gf-violet-300) !important;
  border: 1px solid var(--gf-violet-300);
  border-radius: 2em;
  padding: 0.375rem 0.75rem;
  line-height: 1.6;
}
.btn-outline-info svg {
  width: 0.6em;
  height: 0.6em;
  margin-bottom: 1px;
}
.btn-outline-info:hover {
  border: 1px solid var(--gf-violet-400);
  color: var(--gf-violet-400);
  background-color: #f3f6f9;
  box-shadow: 0px 0px 35px 0px rgba(181, 181, 195, 0.15);
}

.btn-login {
  width: 15%;
}

.btn.sm-rounded {
  border-radius: 10px;
}

.btn.md-rounded {
  border-radius: 15px;
}

.btn.md-rounded {
  border-radius: 20px;
}

.dropdown-menu {
  left: auto;
  right: 0;
  top: calc(100% + 10px);
}

.btn-google {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: 600;
}
.btn-google:hover {
  background-color: #fff !important;
  color: #000;
}

.card-dropdown .dropdown-menu.show {
  left: auto !important;
  right: 0;
  background-color: var(--gf-primary-300);
}
.card-dropdown .dropdown-menu.show button,
.card-dropdown .dropdown-menu.show a {
  color: #fff !important;
  transition: 0.3s ease-in background-color;
}
.card-dropdown .dropdown-menu.show button:hover,
.card-dropdown .dropdown-menu.show a:hover {
  color: var(--gf-primary-300) !important;
}
.card-dropdown .dropdown-menu.show:before {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, 0.15);
  content: "";
  position: absolute;
  right: 8px;
  bottom: 100%;
  display: block;
}
.card-dropdown .dropdown-menu.show :after {
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid var(--gf-primary-300);
  content: "";
  position: absolute;
  right: 9px;
  bottom: 100%;
  display: block;
}

.login--tab {
  border-bottom: 1px solid var(--gf-primary-300);
}

.nav-button {
  width: 100%;
  display: block;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  background-color: transparent;
  color: black !important;
}
.nav-button.active {
  background-color: white;
  color: var(--gf-primary-300) !important;
  border-bottom: 1px solid var(--gf-primary-300);
}
.nav-button:hover {
  border-bottom: 1px solid var(--gf-primary-400);
  background-color: white;
  color: var(--gf-primary-400) !important;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .card-dropdown .dropdown-menu.show {
    transform: none !important;
    top: 3em !important;
  }
}
.table {
  border: none;
}
.table thead th {
  border: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #b5b5c3;
}
.table tbody td {
  border-top: 1px solid;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid;
  border-color: #e5eaee;
  color: #4f4f4f;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  position: relative;
  vertical-align: middle;
}
.table tbody .dt-type-primary {
  color: var(--gf-light-gray-300) !important;
  background-color: var(--gf-primary-300);
  border-radius: 6px;
  padding: 6px 18px;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 45%;
  margin: auto;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.table tbody .dt-type-secondary {
  color: white !important;
  background-color: var(--gf-warning-300);
  border-radius: 6px;
  padding: 6px 18px;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 45%;
  margin: auto;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}
.table tbody .dt-type-info {
  color: var(--gf-dark-violet-300) !important;
  background-color: var(--gf-light-gray-300);
  border-radius: 6px;
  padding: 6px 18px;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 45%;
  margin: auto;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}
.table tbody .dt-date-primary {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--gf-primary-300) !important;
}
.table tbody .dt-status-primary {
  color: var(--gf-primary-300) !important;
  text-transform: capitalize;
}
.table tbody .dt-status-secondary {
  color: var(--gf-warning-300) !important;
  text-transform: capitalize;
}
.table tbody .dt-status-danger {
  color: var(--gf-error-300) !important;
  text-transform: capitalize;
}
.table tbody .dt-multi-list-primary {
  list-style: none;
  padding-left: 0;
  margin-top: 1em;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--gf-primary-300);
}
.table tbody .dt-multi-list-primary li {
  margin-bottom: 6px;
}
.table tbody .dt-multi-list-info {
  list-style: none;
  padding-left: 0;
  margin-top: 1em;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--gf-violet-300);
}
.table tbody .dt-multi-list-info li {
  margin-bottom: 6px;
}
.table tbody .dt-multi-list-warning {
  list-style: none;
  padding-left: 0;
  margin-top: 1em;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--gf-warning-300);
}
.table tbody .dt-multi-list-warning li {
  margin-bottom: 6px;
}

.search-box {
  margin-top: 10px;
  margin-bottom: 10px;
}

.contact-list .table {
  table-layout: auto !important;
}

@media (max-width: 480px) {
  .table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    word-wrap: break-word;
    white-space: nowrap;
  }
}
@media (min-width: 768px) {
  .table {
    display: inline-table;
  }
}
.nav-tabs {
  justify-content: space-around;
  border-bottom: none;
}
.nav-tabs .nav-link {
  width: 100%;
  text-align: center;
  cursor: pointer;
  border-radius: 2em;
  border: 1px solid #e5eaee;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  background-color: #e5eaee;
  color: var(--gf-dark-violet-300) !important;
}
.nav-tabs .nav-link.active {
  border: 1px solid var(--gf-primary-400);
  background-color: white;
  color: var(--gf-primary-400) !important;
  box-shadow: 0px 0px 35px 0px rgba(181, 181, 195, 0.15);
}
.nav-tabs .nav-link:hover {
  border: 1px solid var(--gf-primary-400);
  background-color: white;
  color: var(--gf-primary-400) !important;
  box-shadow: 0px 0px 35px 0px rgba(181, 181, 195, 0.15);
}
.nav-item {
  width: 45%;
}

@media only screen and (max-width: 767px) {
  .nav-item {
    width: 90%;
    margin-bottom: 1em;
  }
}
.react-bootstrap-table-pagination {
  flex-direction: row-reverse;
}
.react-bootstrap-table-pagination > div:first-child {
  width: 50%;
  text-align: end;
}
.react-bootstrap-table-pagination > div:last-child {
  width: 50%;
  display: flex;
}
.react-bootstrap-table-pagination #pageDropDown {
  background-color: white;
  border-color: #e5eaee;
  color: #4f4f4f !important;
}
.react-bootstrap-table-pagination .active.page-item .page-link {
  background-color: var(--gf-primary-300);
  border-color: var(--gf-primary-300);
  color: white;
  border-radius: 100%;
}
.react-bootstrap-table-pagination .page-item .page-link {
  line-height: 1;
}
:root {
  --gf-neutral-50: #fff;
  --gf-neutral-100: #f9f9f9;
  --gf-neutral-200: #ececed;
  --gf-neutral-300: #c4c4c4;
  --gf-neutral-400: #5c5c5c;
  --gf-neutral-500: #000;
  --gf-primary-50: #e6f4f2;
  --gf-primary-100: #9ad1cc;
  --gf-primary-200: #4eafa6;
  --gf-primary-300: #028c80;
  --gf-primary-400: #027066;
  --gf-primary-500: #01544d;
  --gf-error-50: #fef3f2;
  --gf-error-100: #fee4e2;
  --gf-error-200: #fda29b;
  --gf-error-300: #f04438;
  --gf-error-400: #b42318;
  --gf-error-500: #7a271a;
  --gf-warning-50: #fef4e6;
  --gf-warning-100: #fcd39d;
  --gf-warning-200: #f9b153;
  --gf-warning-300: #f79009;
  --gf-warning-400: #c67307;
  --gf-warning-500: #633a04;
  --gf-success-50: #e7f8f0;
  --gf-success-100: #a0e2c3;
  --gf-success-200: #59cd97;
  --gf-success-300: #12b76a;
  --gf-success-400: #0d804a;
  --gf-success-500: #053720;
  --gf-dark-violet-50: #eae9eb;
  --gf-dark-violet-100: #827c89;
  --gf-dark-violet-200: #443a4e;
  --gf-dark-violet-300: #2f243a;
  --gf-dark-violet-400: #2a2034;
  --gf-dark-violet-500: #211929;
  --gf-soft-orange-50: #fff5ed;
  --gf-soft-orange-100: #fed8b9;
  --gf-soft-orange-200: #fdbb84;
  --gf-soft-orange-300: #fc9e4f;
  --gf-soft-orange-400: #ca7e3f;
  --gf-soft-orange-500: #975f2f;
  --gf-light-gray-50: #fbfefe;
  --gf-light-gray-100: #f0faf9;
  --gf-light-gray-200: #e5f7f5;
  --gf-light-gray-300: #daf3f1;
  --gf-light-gray-400: #aec2c1;
  --gf-light-gray-500: #839291;
  --gf-light-gray-orange-50: #fdfbf9;
  --gf-light-gray-orange-100: #f9f0e7;
  --gf-light-gray-orange-200: #f4e5d5;
  --gf-light-gray-orange-300: #efdac3;
  --gf-light-gray-orange-400: #bfae9c;
  --gf-light-gray-orange-500: #8f8375;
  --gf-dark-red-50: #f7eae6;
  --gf-dark-red-100: #dfaa9a;
  --gf-dark-red-200: #c76a4e;
  --gf-dark-red-300: #af2a02;
  --gf-dark-red-400: #8c2202;
  --gf-dark-red-500: #7a1d01;
  --gf-violet-50: #eee6f7;
  --gf-violet-100: #b99adf;
  --gf-violet-200: #854ec7;
  --gf-violet-300: #5002af;
  --gf-violet-400: #40028c;
  --gf-violet-500: #300169;
  --gf-primary-color: var(--gf-primary-300);
  --gf-error-color: var(--gf-error-300);
  --gf-warning-color: var(--gf-warning-300);
  --gf-neutral-color: var(--gf-neutral-300);
  --gf-success-color: var(--gf-success-300);
  /**
  * @tokens Easings
  * @presenter Easing
  */
  --gf-easing-swift: cubic-bezier(0.55, 0, 0.1, 1);
  /**
  * @tokens Font Families
  * @presenter FontFamily
  */
  --base-font-family: "Lato", sans-serif;
  --mono-font-family: Monaco, monospace;
  --web-font-family: "Lato";
  /**
  * @tokens Font Sizes
  * @presenter FontSize
  */
  --gf-fontsize-12: 12px;
  --gf-fontsize-14: 14px;
  --gf-fontsize-16: 16px;
  --gf-fontsize-18: 18px;
  --gf-fontsize-20: 20px;
  --gf-fontsize-23: 23px;
  --gf-fontsize-26: 26px;
  --gf-fontsize-29: 29px;
  --gf-fontsize-32: 32px;
  --gf-fontsize-36: 36px;
  --gf-fontsize-41: 41px;
  --gf-fontsize-46: 46px;
  /**
  * @tokens Font Weights
  * @presenter FontWeight
  */
  --gf-font-weight-regular: 400;
  --gf-font-weight-semibold: 500;
  --gf-font-weight-bold: 600;
  --gf-font-weight-extrabold: 800;
  /**
  * @tokens Line Heights
  * @presenter LineHeight
  */
  --gf-line-height: 1.5;
  /**
  * @tokens Letter Spacings
  * @presenter LetterSpacing
  */
  --gf-letter-spacing-none: 0;
  --gf-letter-spacing-s: 1px;
  --gf-letter-spacing-m: 2px;
  /**
  * @tokens Opacities
  * @presenter Opacity
  */
  --gf-opacity: 0.5;
  /**
  * @tokens Animations
  * @presenter Animation
  */
  --gf-animation-rotate: rotate 1.2s infinite cubic-bezier(0.55, 0, 0.1, 1);
  /**
  * @tokens Border Radius
  * @presenter BorderRadius
  */
  --gf-border-radius-s: 2px;
  --gf-border-radius-m: 4px;
  --gf-border-radius-l: 30px;
  /**
  * @tokens Borders
  * @presenter Border
  */
  --gf-border-normal: 1px solid var(--neutral-300);
  --gf-border-active: 1px solid var(--neutral-400);
  /**
  * @tokens Shadows
  * @presenter Shadow
  */
  --gf-shadow-thin: 0 4px 20px rgba(125, 125, 125, 0.1);
  --gf-shadow-normal: 0 10px 20px -10px rgba(0, 0, 0, 0.25);
  --gf-shadow-thick: 0 4px 20px rgba(125, 125, 125, 0.25);
  /**
  * @tokens Spacings
  * @presenter Spacing
  */
  --gf-gf-spacing-s: 8px;
  --gf-gf-spacing-m: 12px;
  --gf-gf-spacing-l: 16px;
  /**
  * @tokens Z-Index
  */
  --gf-z-index: 1000;
}

:root {
  --toastify-icon-color-error: #fff;
  --toastify-icon-color-success: #fff;
  --toastify-icon-color-error: #fff;
}

.vertical-space {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text-titile-app {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.4em;
  min-height: 3em;
}

.text-black {
  color: #000 !important;
  font-weight: 600;
}

.text-orange {
  color: var(--gf-warning-300) !important;
}

.text-gf-primary {
  color: var(--gf-primary-300);
}
.text-gf-primary:hover {
  color: var(--gf-primary-400);
}

.text-gf-neutral {
  color: var(--gf-neutral-400);
}
.text-gf-neutral:hover {
  color: var(--gf-neutral-500) !important;
}

.Toastify__toast-icon {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 10px;
}

.Toastify__close-button {
  color: #fff !important;
}

.Toastify__toast-body {
  width: 100%;
}

@media (max-width: 767px) {
  .text-titile-app {
    font-size: 12px;
    font-weight: 400;
  }
}
.field-error {
  border: 2px solid red !important;
}

input.text-capitalize {
  text-transform: capitalize;
}

.wrapper-login {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  height: 100%;
}

.layoutWrapper {
  height: 100%;
}

.forgot-label {
  transform: translateY(-60px);
}

.justify-content-evenly {
  min-height: 450px;
  align-items: center;
}

.portal-page .login-container .card {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: max-content;
  min-height: 450px;
  max-width: 900px;
  border-radius: var(--gf-border-radius-m);
}

.otp-container {
  max-width: 75%;
}

.login-container {
  width: 100%;
  margin: auto;
}
.login-container .card {
  min-height: 50vh;
  border-radius: 22px;
}
.login-container .profile-name {
  color: white !important;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 0.6em;
  text-align: center;
  line-height: 1.2;
  margin-top: 20px;
}
.login-container .profile-email {
  color: white !important;
  font-weight: 400;
  /* margin-left: 1em; */
  text-align: center;
  line-height: 1.2;
}

.form-login .form-group label {
  font-size: 16px;
  font-weight: 400;
}
.form-login .forgot-link {
  margin-top: -1rem;
}

.fs-14 {
  font-size: 14px;
}

.form-group.new-account {
  margin: 0;
}
.form-group.new-account p {
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.MuiAutocomplete-inputRoot {
  margin: 0 !important;
}

.MuiAutocomplete-root,
.scope--721888386 .MuiInput-root {
  margin: 0 !important;
}

a.login-forgot {
  font-size: 14px !important;
}

.login-divider {
  position: relative;
  color: #5c5c5c;
}
.login-divider:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 54px;
  background-color: #5c5c5c;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
  left: -6em;
}
.login-divider:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 54px;
  background-color: #5c5c5c;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: -6em;
}

input.login-input {
  border-radius: 0;
  -ms-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -webkit-border-radius: 0;
  border-top-width: 0;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-right-width: 0;
}
input.login-input:focus {
  border-top-width: 0;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-right-width: 0;
  border-color: var(--gf-primary-300);
  outline: none !important;
  box-shadow: 0px 0px 0px #d8d8d8 !important;
  -ms-box-shadow: 0px 0px 0px #d8d8d8;
  -o-box-shadow: 0px 0px 0px #d8d8d8;
  -webkit-box-shadow: 0px 0px 0px #d8d8d8;
  -moz-box-shadow: 0px 0px 0px #d8d8d8;
}

.login-forgot {
  position: absolute;
  right: 0;
  top: -7px;
}

.scope-1140987041 .MuiAutocomplete-root,
.scope-1140987041 .MuiInput-root {
  margin-bottom: 0 !important;
}

@media (max-width: 1023px) {
  .login-container {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .login-container {
    padding-left: 15%;
    padding-right: 15%;
  }
}
@media (min-width: 992px) and (max-width: 1023px) {
  .auth-page .login-block {
    position: relative;
  }
}
@media (max-width: 991px) {
  .auth-page .login-block {
    position: absolute;
    margin-top: auto !important;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .otp-container {
    max-width: 400px;
  }
}
:root {
  --toastify-icon-color-error: #fff;
  --toastify-icon-color-success: #fff;
  --toastify-icon-color-error: #fff;
}

.loading-spinner {
  margin: 1.5em 0;
}

.modal__no-header {
  /* padding-top: 0; */
  border-bottom: 0;
  margin-bottom: -30px;
  z-index: 20;
}

.modal__no-header .btn-close {
  color: var(--gf-primary-300) !important;
  margin-right: 5px;
  margin-top: 5px;
  background: url(https://giftano.com/wimg/asset/times-circle-hv.png);
  width: 24px;
  height: 24px;
  background-size: cover;
}

@media (max-width: 1024px) {
  .h-inherit {
    min-height: inherit;
  }
}
@media (max-width: 768px) {
  .account-greet {
    display: none;
  }
  .h-inherit {
    min-height: auto;
  }
}
@media (max-width: 480px) {
  .account-greet {
    display: none;
  }
  .h-inherit {
    min-height: auto;
  }
}
.fs-20 {
  font-size: 20px;
}

@media only screen and (max-width: 450px) {
  giftano-auth {
    width: 100%;
  }
  .giftano-component {
    padding: 0 !important;
    min-height: 0;
  }
  .grecaptcha-badge {
    display: none !important;
  }
}
@media only screen and (max-width: 480px) {
  .bg-account {
    background-image: none !important;
    background: radial-gradient(309.45% 136% at 50% -41.94%, #eae9eb 12.05%, #028c80 92.58%) !important;
    box-shadow: 5px 8px 16px rgba(0, 0, 0, 0.18);
    border-radius: 22px;
  }
  .bg-account::before {
    background-image: none !important;
    background: radial-gradient(309.45% 136% at 50% -41.94%, #eae9eb 12.05%, #028c80 92.58%) !important;
    box-shadow: 5px 8px 16px rgba(0, 0, 0, 0.18);
    border-radius: 22px;
  }
}
@media only screen and (max-width: 768px) {
  .bg-account {
    background-image: none !important;
    background: radial-gradient(309.45% 136% at 50% -41.94%, #eae9eb 12.05%, #028c80 92.58%) !important;
    box-shadow: 5px 8px 16px rgba(0, 0, 0, 0.18);
    border-radius: 22px;
  }
  .bg-account::before {
    background-image: none !important;
    background: radial-gradient(309.45% 136% at 50% -41.94%, #eae9eb 12.05%, #028c80 92.58%) !important;
    box-shadow: 5px 8px 16px rgba(0, 0, 0, 0.18);
    border-radius: 22px;
  }
}
.auth-page .bg-account::before {
  filter: none;
}

.bg-account {
  background-size: cover;
  position: relative;
}
.bg-account::before {
  background-image: url("https://giftano.imgix.net/assets/giftano-account.90d01e95e357473f85ab.png?auto=format");
  content: "";
  filter: blur(6px);
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;
  background-position: center;
  left: 0;
  top: 0;
}

.bg-default {
  background-size: cover;
}

.bg-team {
  background-image: url("https://thegreatroom.co/wp-content/uploads/2021/05/image-1.jpg");
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .login-block {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    /* border-radius: 26px; */
  }
  .login-block .card {
    border-radius: 4px;
  }
}
:root {
  --gf-neutral-50: #fff;
  --gf-neutral-100: #f9f9f9;
  --gf-neutral-200: #ececed;
  --gf-neutral-300: #c4c4c4;
  --gf-neutral-400: #5c5c5c;
  --gf-neutral-500: #000;
  --gf-primary-50: #e6f4f2;
  --gf-primary-100: #9ad1cc;
  --gf-primary-200: #4eafa6;
  --gf-primary-300: #028c80;
  --gf-primary-400: #027066;
  --gf-primary-500: #01544d;
  --gf-error-50: #fef3f2;
  --gf-error-100: #fee4e2;
  --gf-error-200: #fda29b;
  --gf-error-300: #f04438;
  --gf-error-400: #b42318;
  --gf-error-500: #7a271a;
  --gf-warning-50: #fef4e6;
  --gf-warning-100: #fcd39d;
  --gf-warning-200: #f9b153;
  --gf-warning-300: #f79009;
  --gf-warning-400: #c67307;
  --gf-warning-500: #633a04;
  --gf-success-50: #e7f8f0;
  --gf-success-100: #a0e2c3;
  --gf-success-200: #59cd97;
  --gf-success-300: #12b76a;
  --gf-success-400: #0d804a;
  --gf-success-500: #053720;
  --gf-dark-violet-50: #eae9eb;
  --gf-dark-violet-100: #827c89;
  --gf-dark-violet-200: #443a4e;
  --gf-dark-violet-300: #2f243a;
  --gf-dark-violet-400: #2a2034;
  --gf-dark-violet-500: #211929;
  --gf-soft-orange-50: #fff5ed;
  --gf-soft-orange-100: #fed8b9;
  --gf-soft-orange-200: #fdbb84;
  --gf-soft-orange-300: #fc9e4f;
  --gf-soft-orange-400: #ca7e3f;
  --gf-soft-orange-500: #975f2f;
  --gf-light-gray-50: #fbfefe;
  --gf-light-gray-100: #f0faf9;
  --gf-light-gray-200: #e5f7f5;
  --gf-light-gray-300: #daf3f1;
  --gf-light-gray-400: #aec2c1;
  --gf-light-gray-500: #839291;
  --gf-light-gray-orange-50: #fdfbf9;
  --gf-light-gray-orange-100: #f9f0e7;
  --gf-light-gray-orange-200: #f4e5d5;
  --gf-light-gray-orange-300: #efdac3;
  --gf-light-gray-orange-400: #bfae9c;
  --gf-light-gray-orange-500: #8f8375;
  --gf-dark-red-50: #f7eae6;
  --gf-dark-red-100: #dfaa9a;
  --gf-dark-red-200: #c76a4e;
  --gf-dark-red-300: #af2a02;
  --gf-dark-red-400: #8c2202;
  --gf-dark-red-500: #7a1d01;
  --gf-violet-50: #eee6f7;
  --gf-violet-100: #b99adf;
  --gf-violet-200: #854ec7;
  --gf-violet-300: #5002af;
  --gf-violet-400: #40028c;
  --gf-violet-500: #300169;
  --gf-primary-color: var(--gf-primary-300);
  --gf-error-color: var(--gf-error-300);
  --gf-warning-color: var(--gf-warning-300);
  --gf-neutral-color: var(--gf-neutral-300);
  --gf-success-color: var(--gf-success-300);
  /**
  * @tokens Easings
  * @presenter Easing
  */
  --gf-easing-swift: cubic-bezier(0.55, 0, 0.1, 1);
  /**
  * @tokens Font Families
  * @presenter FontFamily
  */
  --base-font-family: "Lato", sans-serif;
  --mono-font-family: Monaco, monospace;
  --web-font-family: "Lato";
  /**
  * @tokens Font Sizes
  * @presenter FontSize
  */
  --gf-fontsize-12: 12px;
  --gf-fontsize-14: 14px;
  --gf-fontsize-16: 16px;
  --gf-fontsize-18: 18px;
  --gf-fontsize-20: 20px;
  --gf-fontsize-23: 23px;
  --gf-fontsize-26: 26px;
  --gf-fontsize-29: 29px;
  --gf-fontsize-32: 32px;
  --gf-fontsize-36: 36px;
  --gf-fontsize-41: 41px;
  --gf-fontsize-46: 46px;
  /**
  * @tokens Font Weights
  * @presenter FontWeight
  */
  --gf-font-weight-regular: 400;
  --gf-font-weight-semibold: 500;
  --gf-font-weight-bold: 600;
  --gf-font-weight-extrabold: 800;
  /**
  * @tokens Line Heights
  * @presenter LineHeight
  */
  --gf-line-height: 1.5;
  /**
  * @tokens Letter Spacings
  * @presenter LetterSpacing
  */
  --gf-letter-spacing-none: 0;
  --gf-letter-spacing-s: 1px;
  --gf-letter-spacing-m: 2px;
  /**
  * @tokens Opacities
  * @presenter Opacity
  */
  --gf-opacity: 0.5;
  /**
  * @tokens Animations
  * @presenter Animation
  */
  --gf-animation-rotate: rotate 1.2s infinite cubic-bezier(0.55, 0, 0.1, 1);
  /**
  * @tokens Border Radius
  * @presenter BorderRadius
  */
  --gf-border-radius-s: 2px;
  --gf-border-radius-m: 4px;
  --gf-border-radius-l: 30px;
  /**
  * @tokens Borders
  * @presenter Border
  */
  --gf-border-normal: 1px solid var(--neutral-300);
  --gf-border-active: 1px solid var(--neutral-400);
  /**
  * @tokens Shadows
  * @presenter Shadow
  */
  --gf-shadow-thin: 0 4px 20px rgba(125, 125, 125, 0.1);
  --gf-shadow-normal: 0 10px 20px -10px rgba(0, 0, 0, 0.25);
  --gf-shadow-thick: 0 4px 20px rgba(125, 125, 125, 0.25);
  /**
  * @tokens Spacings
  * @presenter Spacing
  */
  --gf-gf-spacing-s: 8px;
  --gf-gf-spacing-m: 12px;
  --gf-gf-spacing-l: 16px;
  /**
  * @tokens Z-Index
  */
  --gf-z-index: 1000;
}

:root {
  --toastify-icon-color-error: #fff;
  --toastify-icon-color-success: #fff;
  --toastify-icon-color-error: #fff;
}

.vertical-space {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text-titile-app {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.4em;
  min-height: 3em;
}

.text-black {
  color: #000 !important;
  font-weight: 600;
}

.text-orange {
  color: var(--gf-warning-300) !important;
}

.text-gf-primary {
  color: var(--gf-primary-300);
}
.text-gf-primary:hover {
  color: var(--gf-primary-400);
}

.text-gf-neutral {
  color: var(--gf-neutral-400);
}
.text-gf-neutral:hover {
  color: var(--gf-neutral-500) !important;
}

.Toastify__toast-icon {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 10px;
}

.Toastify__close-button {
  color: #fff !important;
}

.Toastify__toast-body {
  width: 100%;
}

@media (max-width: 767px) {
  .text-titile-app {
    font-size: 12px;
    font-weight: 400;
  }
}
.account-page .member-image {
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  margin-bottom: 1em;
  height: 10em;
  width: 10em;
  object-fit: cover;
}
.account-page .member-name {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}
.account-page .member-balance {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}
.account-page .member-balance span {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: var(--gf-primary-300) !important;
}
.account-page .member-gifts {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: var(--gf-warning-300);
}

@media only screen and (max-width: 767px) {
  .account-page .member-image {
    margin: 1.5em auto;
    display: block;
  }
}
.order-page .table thead th:last-child {
  text-align: center;
}

.font-weight-bold {
  font-weight: bold;
}

.error-404 {
  font-weight: bold;
  font-size: 120px;
}
.error-message {
  font-weight: normal;
  font-size: 24px;
}

@media (max-width: 480px) {
  .portal-email-and-fullname .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    height: 100%;
  }
  .portal-email-and-fullname .col .profile-email {
    text-align: left !important;
  }
  .portal-email-and-fullname .col .profile-name {
    margin-bottom: 2px;
    margin-top: 0px;
  }
  .wrapper-apps {
    padding: 10px;
  }
}
a {
  transition: 0.3s ease-in;
}

h1,
h2,
h3,
h5,
h6,
span,
label,
button,
input,
small {
  font-family: "Lato", sans-serif !important;
  color: #4f4f4f !important;
  font-weight: 500;
}

h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #4f4f4f;
}

a.nav-link {
  display: inline-block;
  padding-left: 0.5rem !important;
}
a.nav-link:hover {
  text-decoration: none;
  color: var(--gf-primary-400) !important;
}

svg {
  margin-bottom: 1px;
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.font-w-100 {
  font-weight: 100 !important;
}

.font-w-200 {
  font-weight: 200 !important;
}

.font-w-300 {
  font-weight: 300 !important;
}

.font-w-400 {
  font-weight: 400 !important;
}

.font-w-500 {
  font-weight: 500 !important;
}

.font-w-600 {
  font-weight: 600 !important;
}

.font-w-700 {
  font-weight: 700 !important;
}

.font-w-800 {
  font-weight: 800 !important;
}

.fs-1 {
  font-size: 1px;
}

.fs-2 {
  font-size: 2px;
}

.fs-3 {
  font-size: 3px;
}

.fs-4 {
  font-size: 4px;
}

.fs-5 {
  font-size: 5px;
}

.fs-6 {
  font-size: 6px;
}

.fs-7 {
  font-size: 7px;
}

.fs-8 {
  font-size: 8px;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-21 {
  font-size: 21px;
}

.fs-22 {
  font-size: 22px;
}

.fs-23 {
  font-size: 23px;
}

.fs-24 {
  font-size: 24px;
}

.fs-25 {
  font-size: 25px;
}

.fs-26 {
  font-size: 26px;
}

.fs-27 {
  font-size: 27px;
}

.fs-28 {
  font-size: 28px;
}

.fs-29 {
  font-size: 29px;
}

.fs-30 {
  font-size: 30px;
}

.fs-31 {
  font-size: 31px;
}

.fs-32 {
  font-size: 32px;
}

.fs-33 {
  font-size: 33px;
}

.fs-34 {
  font-size: 34px;
}

.fs-35 {
  font-size: 35px;
}

.fs-36 {
  font-size: 36px;
}

.fs-37 {
  font-size: 37px;
}

.fs-38 {
  font-size: 38px;
}

.fs-39 {
  font-size: 39px;
}

.fs-40 {
  font-size: 40px;
}

.fs-41 {
  font-size: 41px;
}

.fs-42 {
  font-size: 42px;
}

.fs-43 {
  font-size: 43px;
}

.fs-44 {
  font-size: 44px;
}

.fs-45 {
  font-size: 45px;
}

.fs-46 {
  font-size: 46px;
}

.fs-47 {
  font-size: 47px;
}

.fs-48 {
  font-size: 48px;
}

.fs-49 {
  font-size: 49px;
}

.text-primary {
  font-family: "Lato", sans-serif !important;
  color: var(--gf-primary-300) !important;
  font-weight: 500;
}

.text-dark {
  font-family: "Lato", sans-serif !important;
  color: #4f4f4f !important;
  font-weight: 500;
}

.radius-none {
  border-radius: 0;
  -ms-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -webkit-border-radius: 0;
}

.sm-radius {
  border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.lg-radius {
  border-radius: 20px;
  -ms-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  -webkit-border-radius: 20px;
}

.md-radius {
  border-radius: 15px;
  -ms-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -webkit-border-radius: 15px;
}

.sm-shadow {
  -webkit-box-shadow: 0px 0px 39px -12px #d8d8d8;
  -moz-box-shadow: 0px 0px 39px -12px #d8d8d8;
  box-shadow: 0px 0px 39px -12px #d8d8d8;
}

.md-shadow {
  box-shadow: 0px 0px 10px #d8d8d8 !important;
  -ms-box-shadow: 0px 0px 10px #d8d8d8;
  -o-box-shadow: 0px 0px 10px #d8d8d8;
  -webkit-box-shadow: 0px 0px 10px #d8d8d8;
  -moz-box-shadow: 0px 0px 10px #d8d8d8;
}

.lg-shadow {
  box-shadow: 0px 0px 15px #d8d8d8 !important;
  -ms-box-shadow: 0px 0px 15px #d8d8d8;
  -o-box-shadow: 0px 0px 15px #d8d8d8;
  -webkit-box-shadow: 0px 0px 15px #d8d8d8;
  -moz-box-shadow: 0px 0px 15px #d8d8d8;
}

.text-danger {
  color: var(--gf-error-300) !important;
}

.text-warning {
  color: var(--gf-warning-300) !important;
}

.text-info-giftano {
  color: var(--gf-violet-300);
  transition: 0.3s ease-in color;
}
.text-info-giftano:hover, .text-info-giftano:focus, .text-info-giftano:active {
  color: var(--gf-violet-400);
  text-decoration: none;
}

.bg-white {
  background-color: #fff;
}

.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

@media (min-width: 767px) {
  .c.portal-container {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .profile-scaffold,
  .menu-grid {
    width: 100% !important;
  }
  .card-portal {
    padding: 0px !important;
  }
  .profile-scaffold {
    border-radius: 0px !important;
  }
}
@media (min-width: 1024px) {
  .portal-page .login-container .card {
    max-height: 85% !important;
  }
}

