input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 16px; /* Firefox */
  color: var(--gf-neutral-400);
  opacity: 1;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 16px; /* Firefox */
  color: var(--gf-neutral-400);
  opacity: 1;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: 16px; /* Firefox */
  color: var(--gf-neutral-400);
  opacity: 1;
}

.pin-input {
  width: 40px;
}

.notice {
  color: var(--gf-soft-orange-200);
  font-size: 14px;
}

.form-subtitle {
  font-size: 16px;
  font-weight: 600;
}

.form-input-otp {
  .form-control {
    height: 40px;
    padding: 5px;
    text-align: center;
    min-width: 2em;
  }
}

.form {
  &-group {
    position: relative;
    h4 {
      @include headline-heavy;
      @media (max-width: 767px) {
        // @include mobile-headline-heavy;
      }
    }
    p {
      @include subheader-light-h4;
      @media (max-width: 767px) {
        // @include mobile-subheader-light-h4;
      }
    }
    label {
      // @include subheader-heavy-h4;
      @media (max-width: 767px) {
        // @include mobile-subheader-heavy-h4;
      }
      width: 100%;
      font-size: 14px;
    }
    input,
    textarea {
      border: 1px solid transparent !important;
      border-radius: $giftano-radius !important;
      background-color: #f7f7f7 !important;
    }
  }
  &-label {
    // @include subheader-heavy-h4;
    color: $giftano-gray-200;
    font-size: 16px;
  }
  &-description {
    // @include subheader-light-h4;
    color: $giftano-gray-300;
  }
}

.form-control {
  height: 40px;
  &:focus {
    border-color: var(--gf-primary-400) !important;
    box-shadow: none !important;
  }
}

// checkbox
.container-check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 3px;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2px solid #2e3a59;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #2e3a59;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.react-tel-input {
  .form-control {
    width: 100% !important;
  }
}

.MuiInputBase-input {
  border: 1px solid transparent !important;
  border-radius: 6px !important;
  background-color: #f7f7f7 !important;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  // padding: 0.375rem 0.75rem;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  // height: 35px;
  height: 28px !important;
  padding: 6px !important;
  width: 100%;
  outline: none;
  padding-left: 0.75rem !important;
}

.MuiInput-underline:after,
.MuiInput-underline:before {
  content: none !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding-right: 39px !important;
}

.MuiAutocomplete-clearIndicator {
  margin-right: 10px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  padding: 0 !important;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 0 !important;
}

.MuiInputAdornment-root {
  p {
    border: 1px solid transparent !important;
    border-radius: 6px !important;
    background-color: #f7f7f7 !important;
    position: relative;
    font-size: 14px;
    letter-spacing: 0.01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 0.375rem 0.75rem;
    margin-left: 0;
    background: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 5px;
    line-height: 25px;
    height: 40px;
    width: 100%;
    outline: none;
    margin-right: 10px;
  }
}

.MuiInput-underline {
  &:before {
    border-bottom: none;
  }
  &:after {
    border-bottom: none;
  }
  &:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 0;
  .MuiAutocomplete-endAdornment {
    right: 0;
  }
}

.MuiAutocomplete-root,
.MuiInput-root {
  margin-bottom: 1rem;
}

.MuiAutocomplete-inputRoot {
  margin-bottom: 1rem;
}

.special-label {
  display: none;
}
