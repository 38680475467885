.auth {
  &-logo {
    width: auto;
    height: 30px;
    height: intrinsic;
    // margin-bottom: 2rem;
    object-fit: contain;
    // margin-bottom: 24px;
  }
  &-version {
    color: white !important;
  }
}

#appleid-signin {
  border-radius: 2em;
  overflow: hidden;
  & > div {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.logout {
  &-logo {
    width: 15em;
    height: intrinsic;
    margin-bottom: 2rem;
  }
}

.forgot {
  &-logo {
    width: 15em;
    height: intrinsic;
    margin-bottom: 2rem;
  }
}

.portal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  & > a img {
    margin-bottom: 1em;
  }
  .profile-scaffold {
    background-color: var(--gf-dark-violet-300);
    border-radius: var(--gf-border-radius-m);
    position: relative;
    text-align: center;
    min-height: 450px;
    &__img {
      height: 100px;
      width: 100px;
      text-align: center;
      background-color: var(--gf-neutral-300);
      margin: 1em auto;
      display: flex;
      /* position: absolute; */
      justify-content: center;
      align-items: center;
      border-radius: 100%;
    }
    img {
      position: absolute;
      bottom: 20px;
      left: 0;
      max-width: 70%;
      right: 0;
      margin: 0 auto;
    }
  }
}

.portal-block {
  // border: 2px solid #000 !important;
  // max-width: 150px;
  // box-shadow: var(--gf-shadow-thin);
  &.protal-loading {
    div.loading {
      height: 150px;
      background-color: rgba(0, 0, 0, 0.2);
      animation: skeleton-anim 1s 0.5s infinite alternate;
    }
    p.loading {
      background-color: rgba(0, 0, 0, 0.2);
      height: 30px;
      animation: skeleton-anim 1s 0.5s infinite alternate;
    }
  }
}

.pin-input {
  width: 40px;
}

// 404 page

.auth-page .login-block {
  position: absolute;
  right: 0;
  height: 100%;
}

.login-block .error-page .login-container {
  padding: 0;
  height: 100%;
}

.default-page .error-page {
  height: 100%;
  position: absolute;
  right: 0;
  width: 50%;
}

.error-page .login-container .card {
  height: 100%;
}

.error-page .login-container .card-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

// reset password
.auth-page .profile-scaffold {
  display: none;
}
.auth-page {
  .reset-password-page {
    .profile-form {
      width: 100% !important;
    }
  }
}
.default-page .reset-password-page {
  margin-top: 10%;
  .card-body {
    padding-top: 5px;
    padding-bottom: 0;
  }

  .profile-form {
    margin-top: 10px;
    padding: 2em 4em;
  }

  .profile-scaffold {
    background-color: var(--gf-dark-violet-300);
    border-radius: var(--gf-border-radius-m);
    position: relative;
    text-align: center;
    min-height: 450px;
  }
  .profile-scaffold__img {
    height: 100px;
    width: 100px;
    text-align: center;
    background-color: var(--gf-neutral-300);
    margin: 1em auto;
    display: flex;
    /* position: absolute; */
    justify-content: center;
    align-items: center;
    border-radius: 100%;
  }
  .profile-scaffold img {
    position: absolute;
    bottom: 20px;
    left: 0;
    max-width: 70%;
    right: 0;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .layoutWrapper {
    padding: 0;
  }
  .portal-page .login-container .card {
    max-width: 100% !important;
    width: 100%;
    left: 0 !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
    // padding-top: 0 !important;
  }

  .portal-container {
    padding-left: 0;
    padding-right: 0;
  }
  .text-sm-center {
    text-align: center;
    font-size: 14px;
  }
  .default-page .error-page {
    height: 60%;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 95%;
    left: 0;
    right: 0;
  }
  .login-container .profile-name {
    font-size: 16px !important;
    // margin-top: 25px !important;
  }

  .portal-container .profile-scaffold {
    display: flex;
    min-height: auto;

    padding-top: 1em;
    padding-bottom: 1em;
  }

  .default-page .reset-password-page .profile-scaffold {
    min-height: auto !important;
  }
  .default-page .reset-password-page .login-container .card {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .default-page .reset-password-page .portal-container {
    padding-top: 0;
    margin-top: -15px !important;
  }

  .default-page .reset-password-page .profile-form {
    min-height: calc(100vh - 107px);
  }
  .auth-page .reset-password-page .portal-container {
    padding: 1rem;
  }

  .auth-page .reset-password-page .portal-container .profile-scaffold {
    display: none;
  }

  .default-page .reset-password-page {
    margin-top: 0;
  }
  .portal-container .profile-scaffold img {
    left: auto;
    right: 25px;
    width: 40px;
    height: 40px;
    margin: auto;
    top: 20px;
  }
  .default-page .reset-password-page .profile-scaffold img {
    left: auto;
    right: 25px;
    margin: auto;
  }
  .portal-container .profile-scaffold__img {
    height: 40px;
    width: 40px;
    text-align: left;
    margin-left: 15px;
    margin-right: 15px;
    svg {
      width: 1em;
      height: 1em;
    }
  }
  .default-page .reset-password-page .portal-container .profile-scaffold__img {
    height: 40px !important;
    width: 40px !important;
    text-align: left !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

// safari only
/* Safari 10.1+ (alternate method) */

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .auth-logo {
      max-width: 75%;
    }
  }
}

@-moz-document url-prefix() {
  @media (min-width: 1024px) {
    .portal-page .login-container .card {
      max-height: 85% !important;
    }
  }
}

@media (min-width: 624px) and (max-width: 767px) {
  .layoutWrapper {
    padding: 0;
  }
  .portal-page .login-container .card {
    max-width: 100% !important;
    width: 100%;
    left: 0 !important;
    padding-top: 0 !important;
    position: relative !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .portal-container {
    padding-left: 0;
    padding-right: 0;
  }
  .text-sm-center {
    text-align: center;
    font-size: 14px;
  }
  .default-page .error-page {
    height: 60%;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 95%;
    left: 0;
    right: 0;
  }
  .login-container .profile-name {
    font-size: 16px !important;
    margin-top: 25px !important;
  }

  .portal-container .profile-scaffold {
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .portal-container .profile-scaffold img {
    left: auto;
    right: 25px;
    width: 40px;
    height: 40px;
    margin: auto;
    top: 20px;
  }

  .portal-container .profile-scaffold__img {
    height: 40px;
    width: 40px;
    text-align: left;
    margin-left: 15px;
    margin-right: 15px;
    svg {
      width: 1em;
      height: 1em;
    }
  }
}

@keyframes skeleton-anim {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}
